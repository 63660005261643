<template>
<div>
<div>
  <img class="img-fluid img_img" src="../assets/images/admin.gif" alt="admin">
  <p>Number of users: {{ numUsers }}</p>
  <p>Number of referrals: {{ numReferrals }}</p>
  <p>!!THERE IS NO UNDO FEATURE!!</p>
  <p>add a new query to a discipline, populate the topics after adding the query and discipline</p>
  <p>discipline (no caps): </p> 
  <input type="text" id="newDiscipline" >
  <p>query name: </p> 
  <input type="text" id="newQuery" ><br>
  <button @click="addQuery">add</button>
  <p>after adding you can find the new query somewhere in the list (random order)</p>
</div>
<div v-for="(doc, index) in queries" :key="index">
    <h4>{{ doc.discipline }} - {{ doc.query }}</h4>
    <button @click="deleteQuery(index)">delete query</button>
      <ul>
        <li v-for="(topic, topicIndex) in doc.topics" :key="topicIndex">
          {{ topic}} - <button @click="deleteTopic(index, topicIndex)">Delete</button>
        </li>
        <li>
          <input type="text" placeholder="new topic" :id="index">
          <button @click="addTopic(index)" > - Add</button>
          </li>
      </ul>
    </div>
</div>
</template> 

<script>
import * as fb from '../lib/firebase'

export default {
  components: {
  },
  data() {
      return {
        queries: [], 
        numUsers: 0, 
        numReferrals: 0,
      }
  },
  methods: {
    loadData() {
      fb.queryCollection.get()
        .then(snapshot => {
          var docs = [];
          snapshot.forEach(doc => {

            const curDoc = doc.data();
            curDoc.id = doc.id;
            docs.push(curDoc);
          });
          this.queries = docs; 
        });
    },
    loadStats() {
      fb.referralCollection.get().then(snapshot => {
        this.numReferrals = snapshot.size;
      }); 
      fb.usersCollection.get().then(snapshot => {
        this.numUsers = snapshot.size;
      }); 
    },
    deleteTopic(index, topicIndex) {
      const doc = this.queries[index];
      const topics = doc.topics;
      topics.splice(topicIndex, 1);
      fb.queryCollection.doc(doc.id).set(
        {
          topics: topics
        }, 
        {
          merge: true
        });
    },
    deleteQuery(index) {
      const doc = this.queries[index];
      fb.queryCollection.doc(doc.id).delete().then(() => {
        this.loadData();
      });
    },
    addQuery() {
      const newDiscipline = document.getElementById('newDiscipline').value; 
      const newQuery = document.getElementById('newQuery').value;

      fb.queryCollection.add({
        discipline: newDiscipline,
        query: newQuery,
        topics: []
      }).then(() => {
        this.loadData();

      });
    }, 
    addTopic(index) {
      const field = document.getElementById(index).value; 
      const topics = Array.isArray(this.queries[index].topics) ? this.queries[index].topics : [];

      topics.push(field);
      fb.queryCollection.doc(this.queries[index].id).set({
        topics: topics,
      }, {
        merge: true,
      });

    },
  },
  mounted() {
    this.loadData();
    this.loadStats(); 
  },
}
</script>

<style scoped>
input {
  border: 1px solid black;
}
button {
  border: 1px solid red;
}
</style>