<template>
  <div>
    <p>Logging out..</p>
  </div>
</template>

<script>

export default {
  methods: {
    logout() {
      this.$store.dispatch('logout');
    }
  }, 
  mounted() {
    this.logout();
  }
};
</script>