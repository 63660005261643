<template>
<div>
<div class="row ml-2">
  <p>
    <button class="btn btn-secondary" type="button" @click="isShow = !isShow">Show extracted PICO</button>
  </p>
</div>

<transition name="slide-fade">
  <div class="row ml-2 mr-2" v-show="isShow">

    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0" v-if="p">
      <div class="card card-body PICO-card">
          <p>
            Patient:
          </p>
          <p class="p-custom-dashboard1">
            {{ p | getLongestSentence }}
          </p>
      </div>
    </div>

    <div class="col-xl-3 col-lg-t col-md-6 col-sm-12 col-xs-12 pl-0" v-if="i">
        <div class="card card-body PICO-card">
          <p>
            Intervention:
          </p>
          <p class="p-custom-dashboard1">
            {{ i | getLongestSentence }}
          </p>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0" v-if="c">
        <div class="card card-body PICO-card">
          <p>
            Control:
          </p>
          <p class="p-custom-dashboard1">
            {{ c | getLongestSentence }}
          </p>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0" v-if="o">
        <div class="card card-body PICO-card">
          <p>
            Outcome:
          </p>
          <p class="p-custom-dashboard1">
            {{ o | getLongestSentence }}
          </p>
        </div>
    </div>

  </div>
  </transition>
  
</div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(0.8, 0.3, 0.5, 0.3);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>

<script>
 export default {
  props: ["p", "i", "c", "o"],
  data: function () {
    return {
      isShow: false,
    };
  },
 }
</script>
