<template>
<div class="row ml-2 mr-2 mb-3 align-items-end">
  <div class="col-lg-6 col-md-12 col-sm-12">
     <v-select
      v-model="selectedDisciplines"
      :items="disciplines"
      item-text="label"
      item-value="id"
      label="discipline"
      multiple
      chips
      deletable-chips
    ></v-select>
    <v-select
      v-model="selectedCategories"
      :items="categories"
    
      item-text="label"
      item-value="id"
      label="categories"
      multiple
      chips
      deletable-chips
    ></v-select>

    <v-btn
      @click="reloadPage"
    >
      Load
    </v-btn>

 <TextHighlight :queries="queries" :highlightClass="highlightClass">{{ description }}</TextHighlight>

    <div v-for="doc in docs" :key="doc.pmid" > 
      <ul>
        <li><h3>{{ doc.title }}</h3></li>
        <li><p>{{ doc.abstract }}</p></li>
        <li><p>N = {{doc.sampleSize}}</p></li>
        <li><p>ROB total = {{ doc.robTotal}}, ROB1 = {{ doc.rob1 }}, ROB2 = {{ doc.rob2 }}, ROB3 = {{ doc.rob3 }}, ROB4 = {{ doc.rob4 }}, ROB5 = {{ doc.rob5 }}</p></li>
        <li><p>punchline = {{ doc.bottomLine }}</p></li>
        <li><p>P = {{ doc.p }}</p></li>
        <li><p>I = {{ doc.i }}</p></li>
      </ul>
      <hr />
    </div>
    <v-pagination
      v-model="page"
      :length="numDocs"
      @input="loadPage"
      :total-visible="7"
    ></v-pagination>
  </div>
</div>
</template>
<script>
import disciplines from "../lib/variables";
import { mapActions, mapState } from "vuex";

import TextHighlight from 'vue-text-highlight';

export default {
  data() {
    return {
      description: "test text here",
      queries: ["test", "text"],
      highlightClass: ["one", "two"],
      page: 1,
      pageReview: 1,
      page_size: 100,
      disciplines,
      selectedDisciplines: ["anesthesiology", "cardiology", "dermatology", 
        "gastroenterology", "gynecology", "ophthalmology", "respiratory", 
        "rheumatology", "surgery", "urology"],
      selectedCategories: ["anesthesiology", "cardiology", "dermatology", 
        "gastroenterology", "gynecology", "ophthalmology", "respiratory", 
        "rheumatology", "surgery", "urology"],
      timeDelta: 14,
    };
  },
  components: {
    TextHighlight,
  },
  mounted() {
    this.reloadPage();
  },
  computed: {
    ...mapState([
      "docs",
      "numDocs",
      "loadingRcts",
    ]),
    categories() {
      const categories = new Set();

      disciplines
        .filter((discipline) =>
          this.selectedDisciplines && this.selectedDisciplines.includes(discipline.id)
        )
        .forEach((discipline) => {
          (discipline.categories || []).forEach((cat) => categories.add(cat));
        });

      return [...categories];
    },
  },
  watch: {
    userProfile: function () {
      if (this.userProfile.disciplines && this.userProfile.categories) {
        this.selectedDisciplines = this.userProfile.disciplines;
        this.selectedCategories = this.userProfile.categories;
      }
      this.reloadPage();
    },
  },
  methods: {
    ...mapActions(["loadDocs"]),
    //get rcts from server
    loadPage() {

      this.loadDocs({
        selectedDisciplines: this.selectedDisciplines,
        selectedCategories: this.selectedCategories,
        timeDelta: this.timeDelta,
        page: this.page,
        type: "rct",
      });

      },
    reloadPage() {
      this.loadPage();
    },
  },
};
</script>