const disciplines = [
  { id: "allergy_and_autoimmunity", label: "allergy and autoimmunity", categories: [
    {id:"allergy_and_autoimmunity_allergies", label: "allergies"},
    {id:"allergy_and_autoimmunity_vasculitis_autoimmune", label: "vasculitis autoimmune"},
    {id:"allergy_and_autoimmunity_multisystem_autoimmune_disease", label: "multisystem autoimmune disease"},
    {id:"allergy_and_autoimmunity_endocrine_and_exocrine_autoimmune_disease", label: "endocrine and exocrine autoimmune disease"},
    {id:"allergy_and_autoimmunity_digestive_tract_autoimmune_disease", label: "digestive tract autoimmune disease"},
    {id:"allergy_and_autoimmunity_immunodeficiency_syndromes", label: "immunodeficiency syndromes"},
    {id:"allergy_and_autoimmunity_other_autoimmune_disease", label: "other autoimmune disease"},
    ] },
  { id: "cardiology", label: "cardiology", categories: [
    {id: "cardiology_cardiac_arrhythmia", label: "cardiac arrhythmia"},
    {id: "cardiology_cardiomegaly", label: "cardiomegaly"},
    {id: "cardiology_cardiomyopathy", label: "cardiomyopathy"},
    {id: "cardiology_heart_failure", label: "heart failure"},
    {id: "cardiology_ischemic_heart_disease", label: "ischemic heart disease"},
    {id: "cardiology_congenital_heart_defects", label: "congenital heart defects"},
    {id: "cardiology_vascular_disease", label: "vascular disease"},
    {id: "cardiology_heart_neoplasia", label: "heart neoplasia"},
    {id: "cardiology_pericardial_disorders", label: "pericardial disorders"},
    {id: "cardiology_valvular_heart_disease", label: "valvular heart disease"},
  ] },
  { id: "dermatology", label: "dermatology", categories: [
    {id: "dermatology_eczematous_skin_diseases", label: "eczematous skin diseases"},
    {id: "dermatology_oncological_skin_diseases", label: "oncological skin diseases"},
    {id: "dermatology_infectious_skin_diseases", label: "infectious skin diseases"},
    {id: "dermatology_autoimmune_skin_diseases", label: "autoimmune skin diseases"},
    {id: "dermatology_phlebology", label: "phlebology"},
    {id: "dermatology_sexually_transmitted_skin_diseases", label: "sexually transmitted skin diseases"},
    {id: "dermatology_hair_nails_and_sweat_gland_skin_diseases", label: "hair nails and sweat gland skin diseases"},
    {id: "dermatology_pigmentation_abnormalities", label: "pigmentation abnormalities"},
  ] },
  { id: "endocrinology", label: "endocrinology", categories: [
    {id: "endocrinology_glucose_homeostasis_disorders", label: "glucose homeostasis disorders"},
    {id: "endocrinology_thyroid_disorders", label: "thyroid disorders"},
    {id: "endocrinology_pituitary_gland_disorders", label: "pituitary gland disorders"},
    {id: "endocrinology_adrenal_diseases", label: "adrenal diseases"},
    {id: "endocrinology_sex_hormone_and_development_disorder", label: "sex hormone and development disorder"},
    {id: "endocrinology_calcium_and_bone_disease", label: "calcium and bone disease"},
    {id: "endocrinology_other", label: "other"},
  ] },
  { id: "gastroenterology", label: "gastroenterology", categories: [
    {id: "gastroenterology_hepatic_disease", label: "hepatic disease"},
    {id: "gastroenterology_pancreatic_disease", label: "pancreatic disease"},
    {id: "gastroenterology_gastric_disease", label: "gastric disease"},
    {id: "gastroenterology_intestinal_disease", label: "intestinal disease"},
    {id: "gastroenterology_esophageal_disease", label: "esophageal disease"},
    {id: "gastroenterology_gall_bladder_disease", label: "gall bladder disease"},
  ] },
  { id: "general_surgery", label: "general surgery", categories: [
    {id: "general_surgery_esophagus", label: "esophagus"},
    {id: "general_surgery_stomach", label: "stomach"},
    {id: "general_surgery_small_intestine", label: "small intestine"},
    {id: "general_surgery_large_intestine", label: "large intestine"},
    {id: "general_surgery_recto-anal", label: "recto-anal"},
    {id: "general_surgery_gallbladder", label: "gallbladder"},
    {id: "general_surgery_pancreas", label: "pancreas"},
    {id: "general_surgery_liver", label: "liver"},
    {id: "general_surgery_thyroid_gland", label: "thyroid gland"},
    {id: "general_surgery_adrenal_gland", label: "adrenal gland"},
    {id: "general_surgery_hernia_surgery", label: "hernia surgery"},
  ] },
  { id: "hematology", label: "hematology", categories: [
    {id: "hematology_anemia", label: "anemia"},
    {id: "hematology_hemostasis_and_coagulation_disorders", label: "hemostasis and coagulation disorders"},
    {id: "hematology_blood_protein_disorders", label: "blood protein disorders"},
    {id: "hematology_hemoglobinopathies", label: "hemoglobinopathies"},
    {id: "hematology_bone_marrow_disorders", label: "bone marrow disorders"},
    {id: "hematology_lymphoproliferative_disease", label: "lymphoproliferative disease"},
    {id: "hematology_vascular_tissue_neoplasms", label: "vascular tissue neoplasms"},
    {id: "hematology_blood_cell_anomalies", label: "blood cell anomalies"},
  ] },
  { id: "infectious_diseases", label: "infectious diseases", categories: [
    {id: "infectious_diseases_bacterial_infections", label: "bacterial infections"},
    {id: "infectious_diseases_viral_infections", label: "viral infections"},
    {id: "infectious_diseases_mycoses", label: "mycoses"},
    {id: "infectious_diseases_parasitic_infections", label: "parasitic infections"},
    {id: "infectious_diseases_vector_borne_diseases", label: "vector borne diseases"},
    {id: "infectious_diseases_sexually_transmitted_disease", label: "sexually transmitted disease"},
    {id: "infectious_diseases_zoonosis", label: "zoonosis"},
  ] },
  { id: "nephrology", label: "nephrology", categories: [
    {id: "nephrology_acute_kidney_disease", label: "acute kidney disease"},
    {id: "nephrology_chronic_kidney_disease", label: "chronic kidney disease"},
    {id: "nephrology_vascular_kidney_disease", label: "vascular kidney disease"},
    {id: "nephrology_infectious_kidney_disease", label: "infectious kidney disease"},
    {id: "nephrology_congenital_and_inherited_kidney_disease", label: "congenital and inherited kidney disease"},
    {id: "nephrology_kidney_neoplasms", label: "kidney neoplasms"},
    {id: "nephrology_other", label: "other"},
  ] },
  { id: "neurology", label: "neurology", categories: [
    {id: "neurology_other", label: "other"},
    {id: "neurology_cerebrovascular_diseases", label: "cerebrovascular diseases"},
    {id: "neurology_congenital_anomalies", label: "congenital anomalies"},
    {id: "neurology_epilepsy", label: "epilepsy"},
    {id: "neurology_infectious_diseases", label: "infectious diseases"},
    {id: "neurology_prion_diseases", label: "prion diseases"},
    {id: "neurology_metabolic_diseases", label: "metabolic diseases"},
    {id: "neurology_autoimmune_diseases", label: "autoimmune diseases"},
    {id: "neurology_headache_disorders", label: "headache disorders"},
    {id: "neurology_motor_neuron_disease", label: "motor neuron disease"},
    {id: "neurology_movement_disorders", label: "movement disorders"},
    {id: "neurology_neuromuscular_disorders", label: "neuromuscular disorders"},
    {id: "neurology_nervous_system_neoplasms", label: "nervous system neoplasms"},
    {id: "neurology_neurocutaneous_conditions", label: "neurocutaneous conditions"},
    {id: "neurology_neurodegenerative_disorders", label: "neurodegenerative disorders"},
    {id: "neurology_sleep_disorders", label: "sleep disorders"},
    {id: "neurology_pituitary_diseases", label: "pituitary diseases"},
  ] },
  { id: "neurosurgery", label: "neurosurgery", categories: [
    {id: "neurosurgery_general", label: "general"},
    {id: "neurosurgery_vascular", label: "vascular"},
    {id: "neurosurgery_epilepsy", label: "epilepsy"},
    {id: "neurosurgery_oncological", label: "oncological"},
    {id: "neurosurgery_skull_base", label: "skull base"},
    {id: "neurosurgery_spinal", label: "spinal"},
    {id: "neurosurgery_perhiperal_nerve", label: "perhiperal nerve"},
    {id: "neurosurgery_pediatric", label: "pediatric"},
    {id: "neurosurgery_basal_ganglia", label: "basal ganglia"},
  ] },
  { id: "oncology", label: "oncology", categories: [
    {id: "oncology_sarcoma", label: "sarcoma"},
    {id: "oncology_urinary_bladder_neoplasms", label: "urinary bladder neoplasms"},
    {id: "oncology_breast_neoplasms", label: "breast neoplasms"},
    {id: "oncology_digestive_system_neoplasms", label: "digestive system neoplasms"},
    {id: "oncology_endocrine_neoplasms", label: "endocrine neoplasms"},
    {id: "oncology_lung_neoplasms", label: "lung neoplasms"},
    {id: "oncology_germ_cell_and_embryonal_neoplasms", label: "germ cell and embryonal neoplasms"},
  ] },
  { id: "pediatric_heart_surgery", label: "pediatric heart surgery", categories: [
    {id: "pediatric_heart_surgery_congenital_heart_defect_surgery", label: "congenital heart defect surgery"},
  ] },
  { id: "psychiatry", label: "psychiatry", categories: [
    {id: "psychiatry_anxiety_disorders", label: "anxiety disorders"},
    {id: "psychiatry_attention_deficit_disorder", label: "attention deficit disorder"},
    {id: "psychiatry_disruptive_impulse_control_and_conduct_disorders", label: "disruptive impulse control and conduct disorders"},
    {id: "psychiatry_dissociative_disorders", label: "dissociative disorders"},
    {id: "psychiatry_eating_and_feeding_disorders", label: "eating and feeding disorders"},
    {id: "psychiatry_mood_disorders", label: "mood disorders"},
    {id: "psychiatry_neurodevelopmental_disorders", label: "neurodevelopmental disorders"},
    {id: "psychiatry_paraphilic_disorders", label: "paraphilic disorders"},
    {id: "psychiatry_personality_disorders", label: "personality disorders"},
    {id: "psychiatry_schizophrenia_and_psychosis", label: "schizophrenia and psychosis"},
    {id: "psychiatry_sexual_dysfunction_psychological", label: "sexual dysfunction psychological"},
    {id: "psychiatry_sleep_wake_disorders", label: "sleep wake disorders"},
    {id: "psychiatry_somatoform_disorders", label: "somatoform disorders"},
    {id: "psychiatry_substance-related_disorders", label: "substance-related disorders"},
    {id: "psychiatry_trauma_and_stressor-related_disorders", label: "trauma and stressor-related disorders"},
    {id: "psychiatry_other", label: "other"},
  ] },  
  { id: "pulmonology", label: "pulmonology", categories: [
    {id: "pulmonology_obstructive_lung_disease", label: "obstructive lung disease"},
    {id: "pulmonology_restrictive_lung_disease", label: "restrictive lung disease"},
    {id: "pulmonology_pulmonary_infection_and_inflammation", label: "pulmonary infection and inflammation"},
    {id: "pulmonology_pulmonary_neoplasms", label: "pulmonary neoplasms"},
    {id: "pulmonology_other", label: "other"},
  ] },  
  { id: "surgical_oncology", label: "surgical oncology", categories: [
    {id: "surgical_oncology_general", label: "general"},
    {id: "surgical_oncology_breast_oncological", label: "breast oncological"},
    {id: "surgical_oncology_lung_oncological", label: "lung oncological"},
    {id: "surgical_oncology_esophagus_cancer", label: "esophagus cancer"},
    {id: "surgical_oncology_gastric_cancer", label: "gastric cancer"},
    {id: "surgical_oncology_pancreatic_cancer", label: "pancreatic cancer"},
    {id: "surgical_oncology_liver_cancer", label: "liver cancer"},
    {id: "surgical_oncology_intestinal_cancer", label: "intestinal cancer"},
    {id: "surgical_oncology_recto-anal_cancer", label: "recto-anal cancer"},
  ] },
  { id: "thoracic_surgery", label: "thoracic surgery", categories: [
    {id: "thoracic_surgery_heart", label: "surgery heart"},
    {id: "thoracic_surgery_lung", label: "surgery lung"},
    {id: "thoracic_surgery_general", label: "surgery general"},
  ] }, 
  { id: "trauma_surgery", label: "trauma surgery", categories: [
    {id: "trauma_surgery_general", label: "general"},
    {id: "trauma_surgery_thoracic_trauma", label: "thoracic trauma"},
    {id: "trauma_surgery_bone_trauma", label: "bone trauma"},
    {id: "trauma_surgery_neurosurgical_trauma", label: "neurosurgical trauma"},
    {id: "trauma_surgery_burn_and_electrical_injury", label: "burn and electrical injury"},
  ] },  
  { id: "vascular_surgery", label: "vascular surgery", categories: [
    {id: "vascular_surgery_carotid_artery", label: "carotid artery"},
    {id: "vascular_surgery_peripheral", label: "peripheral"},
    {id: "vascular_surgery_aortic", label: "aortic"},
    {id: "vascular_surgery_abdominal_aneurysm", label: "abdominal aneurysm"},
    {id: "vascular_surgery_thoracic_aneurysm", label: "thoracic aneurysm"},
    {id: "vascular_surgery_other", label: "other"},
  ] },
  { id: "vascular_medicine", label: "vascular medicine", categories: [
    {id: "vascular_medicine_aortic_disease_and_aneurysms", label: "aortic disease and aneurysms"},
    {id: "vascular_medicine_arterial_disease", label: "arterial disease"},
    {id: "vascular_medicine_venous_disease", label: "venous disease"},
    {id: "vascular_medicine_vasculitis_autoimmune", label: "vasculitis autoimmune"},
    {id: "vascular_medicine_cardiovascular_risk", label: "cardiovascular risk"},
    {id: "vascular_medicine_ulcers", label: "ulcers"},
    {id: "vascular_medicine_lymphedema", label: "lymphedema"},
  ] },
];

module.exports = disciplines;