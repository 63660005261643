import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

import { auth } from './lib/firebase'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import vuetify from './plugins/vuetify';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  appName: "RCTAlert", 
  pageTrackerScreenviewEnabled: true,
  config: {id: "G-1Y42JSKDRP"}
}, router);

library.add(fab, fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// import TextHighlight from 'vue-text-highlight';
// Vue.component('text-highlight', TextHighlight);

import disciplines from "./lib/variables";

//filters 
const lut = disciplines.reduce((collect, doc) => {
  doc.categories.forEach(doc => collect[doc.id] = doc.label)
  return collect;
}, {});
Vue.filter('formatFirebaseCategory', function(values) {
  return values
  .map((value) => {
    return lut[value]
  })
  .join(", ")
});

Vue.filter('formatFirebaseDiscipline', function(value) {
  return value.replaceAll("_", " ").replaceAll(",", ", ");
});

Vue.filter('formatFirebaseDate', function(value) {
  if (value && value._seconds) {
    return new Date(value._seconds * 1000 ).toLocaleDateString();
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return value.toDate().toLocaleDateString();
  }
});

Vue.filter('getLongestSentence', function(value) {
  if(value) {
    return value.split(' ; ').reduce(function(longest, currentWord) {
      return currentWord.length > longest.length ? currentWord : longest;
    }, "");
  }
});

Vue.filter('formatFirebaseDateUpdates', function(value) {
  if (value) {
    // return value; 
    var t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(value);
    return t.toLocaleDateString();
  }
});

Vue.filter('truncate', function(text, length, clamp){
  clamp = clamp || '...';
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('highlight', function(text, query) {

  return text.replace(query, '<mark>' + query + '</mark>');
});


//init vue

Vue.config.productionTip = false


let app 
auth.onAuthStateChanged((user) => {
  if(!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user);
  }
})

