<template>
<div>
    <!-- Salutation -->
    <div class="settings-bar">
        <div class="row ml-2 mr-2 mb-3 ">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <h5 class="mb-5">
                    Welcome <span>{{ userProfile && userProfile.firstName }}</span>!
                    <p>
                        <span style="font-size: 14px">Below we lined up this week's articles for you.</span>
                    </p>
                    <p class="mt-5">
                        You have two options:
                        <ul class="mr-5 pr-5">
                            <li class="text-justify">
                                <span style="font-size: 14px">
                                    Select a medical specialty on the right. Click ‘Save’ to directly view the latest evidence. It is possible to (de)select subspecialties within a medical specialty.
                                </span>
                            </li>
                            <li class="text-justify">
                                <span style="font-size: 14px">
                                    Want to customize your e-alert and search your specific clinical evidence? Try it <a href="https://picosearch.io" target="_blank" style="color: #1717D9;">directly here</a>.
                                </span>
                            </li>
                        </ul>
                    </p>
                </h5>
            </div>

            <!-- Settings: change categories -->
            <div class="col-lg-6 col-md-12 col-sm-12 text-lg-right">
                <v-select v-model="selectedDisciplines" color="primary" v-on:change="toggleNewDiscipline" :items="uniqueDisciplines" item-value="id" label="discipline" multiple chips deletable-chips></v-select>
                <!-- <v-select v-model="selectedCategories" color="primary" :items="possibleCategories" multiple chips deletable-chips></v-select> -->

                <v-select v-model="selectedCategories" :items="possibleCategories" label="categories" multiple chips deletable-chips>

                    <!-- limit the amount of visible chips to max. of 7 -->
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index <= 6" close @click:close="remove(item)">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 7" class="grey--text caption">
                            (+{{ selectedCategories.length - 7 }} others)
                        </span>
                    </template>

                    <!-- Add a tile that can select all diseases with custom methods-->
                    <v-list-item slot="prepend-item" ripple @click="toggle">

                        <v-list-item-action>
                            <v-icon :color="selectedCategories && selectedCategories.length > 0 ? 'indigo darken-4' : ''">{{ iconCategories }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-title>
                            select all
                        </v-list-item-title>

                    </v-list-item>

                    <v-divider slot="prepend-item" class="mt-2" />
                </v-select>

                <v-btn @click="saveProfile">
                    Save
                </v-btn>
            </div>

            <div class="form-row ml-2">
                <div class="col-10 my-auto">
                    <p class="my-auto p-custom-dashboard1">
                        Recommend RCTAlert to a colleague:
                    </p>
                    <div v-if="!referralSend">
                        <v-text-field v-model="referralEmail" label="E-mail" :rules="emailRules"></v-text-field>
                    </div>
                </div>
                <div v-if="!referralSend" class="col-2 my-auto" @click="sendReferralMail">
                    <v-btn small>send!</v-btn>
                </div>
                <div v-else-if="referralSend" class="col-10 my-auto">
                    <p class="my-auto p-custom-dashboard1">
                        E-mail sent!
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- This week's RCTs -->
    <div class="container-fluid mb-5 dashboard">
        <div class="background-dashboard">
            <div class="row ml-2 mt-2">
                <h3 class="h3-custom" style="color: #4f4f4f; margin: 2.083333% 0">
                    This week's articles
                </h3>
            </div>

            <!-- sample size header -->
            <div class="row ml-2">
                <div class="col-6"></div>
                <div class="col-2">
                    <!-- <p class="p-custom-dashboard4 RoB-header">
            <b>Estimated RCT Quality:</b>
          </p> -->
                </div>
                <div class="col-sm-2">
                    <p class="p-custom-dashboard4 sample-size-header">
                        <b>Sample Size:</b>
                    </p>
                </div>
            </div>

            <div v-if="loadingUpdates">
                <center>Loading...</center>
            </div>
            <div v-else>
                <div v-if="numDocsUpdates === 0">
                    <center>No papers found...</center>
                </div>

                <div v-for="doc in docsUpdates" :key="doc.pmid">
                    <!-- article  -->

                    <!-- labels  -->
                    <!-- <div class="row mt-3 ml-2">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-10">
              <div class="row">
                <p class="mb-3 p-custom-dashboard1">
                  <font-awesome-icon :icon="['fas', 'tag']" /> -->
                    <!-- <b> {{ doc.discipline }}</b> -->
                    <!-- <span>: </span><span>{{ doc.category | formatFirebaseCategory }}</span><br> -->
                    <!-- </p>
              </div>
            </div>
          </div> -->

                    <!-- date and journal  -->
                    <div class="row ml-2">
                        <div class="col-8">
                            <div class="row">
                                <p class="p-custom-dashboard1">
                                    {{ doc.date | formatFirebaseDateUpdates }}
                                </p>
                                <p class="p-custom-dashboard1">|</p>
                                <p class="p-custom-dashboard1">{{ doc.jabbrv }}</p>
                            </div>
                        </div>

                        <!-- sample size  -->
                        <div class="col text-right">
                            <div class="row mx-auto">
                                <p class="no-header-text p-custom-dashboard-sample-size">
                                    {{ doc.n === undefined ? "" : "Size = ", }}
                                    <span>
                                        {{ doc.n || "" }}
                                    </span>
                                </p>
                                <!-- insert sample size number here (placeholder #) -->
                            </div>
                        </div>
                    </div>

                    <!-- title of paper  -->
                    <div class="row ml-2 sm-mr-2">
                        <div class="col-lg-7 col-md-7 col-10">
                            <div class="row">
                                <!-- <a target="_blank" href=""> -->
                                <p class="p-custom-dashboard2">{{ doc.title }}</p>
                                <!-- </a> -->
                            </div>
                        </div>

                        <!-- pubmed url  -->
                        <div class="col-lg-1 col-md-1 col-2">
                            <tracking-link :uid=userProfile.uid :doi=doc.doi :pmid=doc.pmid />
                        </div>

                        <!-- RoB STARS ON FULL LG SCREEN -->
                        <!-- <div class="sample-size-header col-lg-2 col-md col-sm">
              <div class="row RoB-header">
                <div class="col-12 text-right">
                  <ul class="no-padding">
                    <li style="display: inline-block" v-for="i in [1, 2, 3, 4, 5]" :key="i">
                      <img src="../assets/images/star-full.svg" v-if="i <= doc.robTotal" width="16" alt="1" />
                      <img src="../assets/images/star-empty.svg" v-else width="16" alt="1" />
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->

                        <div class="sample-size-header col-2 col-sm-0">
                            <p class="p-custom-dashboard-sample-size">
                                <span class="with-header-text">
                                    {{ doc.n || "" }}
                                </span>
                                <!-- insert sample size number here (placeholder #) -->
                            </p>
                        </div>
                    </div>

                    <!-- author  -->
                    <div class="row ml-2 no-padding">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="row">
                                <p class="p-custom-dashboard3 no-padding">{{ doc.author }}</p>
                            </div>
                        </div>

                        <div class="col-sm-0"></div>

                        <div class="col-sm-0"></div>
                    </div>

                    <!-- abstract  -->
                    <div class="row mt-2 mb-3 ml-2 sm-mr-2">
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <div class="row">
                                <p class="p-custom-dashboard1 text-justify" v-if="doc.b">
                                    <b>Extracted summary:</b>
                                    {{ doc.b | getLongestSentence | truncate(500, "...") }}
                                </p>

                                <p class="p-custom-dashboard1 text-justify" v-else>
                                    <b>Abstract:</b> {{ doc.abstract | truncate(500, "...") }}
                                </p>

                                <!-- impact factor: {{ doc.impact_factor }} -->
                                <!-- <div v-if="userProfile && userProfile.isAdmin">
                  <p><b>admin:</b></p>
                  <p>impact factor: {{ doc.impact_factor }}</p>
                </div> -->
                            </div>
                        </div>
                        <div class="col-lg col-md"></div>
                    </div>

                    <!-- PICO -->
                    <PICO :p=doc.p :i=doc.i :c=doc.c :o=doc.o v-if="doc.p || doc.i || doc.c || doc.o"></PICO>
                    <hr />

                    <!-- END ARTICLE -->
                </div>
            </div>

            <!-- Paginator -->
            <div class="row">
                <div class="col-12 ml-2">
                    <div class="text-center">
                        <v-pagination v-model="page" :length="numPagesUpdates" @input="loadUpdates" :total-visible="7"></v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- unsubscribe button -->
    <div class="container-sm">
        <div class="salutation">
            <div class="row mt-12 mb-4">
                <div class="col text-center">
                    <a @click="toggleSub">
                        <button type="button" class="btn btn-unsubscribe no-y-padding" style="border-radius: 8px">
                            <div v-if="userProfile.alert">
                                <p class="p-custom-unsubscribe no-wrap no-y-padding">
                                    unsubscribe from weekly mail
                                </p>
                            </div>
                            <div v-else>
                                <p class="p-custom-unsubscribe no-wrap no-y-padding">
                                    subscribe to weekly mail
                                </p>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import disciplines from "../lib/variables";
import {
    mapActions,
    mapState
} from "vuex";
import intersection from "lodash/intersection";
import TrackingLink from "../components/TrackingLink";
import PICO from "../components/PICO.vue";

export default {
    components: {
        TrackingLink,
        PICO
    },
    data() {
        return {
            page: 1,
            page_size: 5,
            selectedDisciplines: [],
            selectedCategories: [],
            selectedQueries: [],
            selectedDisciplinesOld: [],
            referralEmail: "",
            referralSend: false,
            emailRules: [
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
        };
    },
    mounted() {
        this.selectedDisciplines = this.userProfile.disciplines;
        this.selectedCategories = this.userProfile.categories;

        this.getQueries();

    },
    computed: {
        ...mapState([
            "docsUpdates",
            "numDocsUpdates",
            "numPagesUpdates",
            "loadingUpdates",
            "userProfile",
            "uniqueDisciplines",
            "queries",
        ]),
        possibleCategories: function () {
            let simpleQ = [];
            if (this.queries && this.queries.length > 0 && this.selectedDisciplines && this.selectedDisciplines.length > 0) {
                this.queries.forEach((query) => {
                    if (this.selectedDisciplines.includes(query.discipline)) {
                        simpleQ.push(query.query);
                    }
                });
            }
            return simpleQ;

        },

        allCategories() {
            return this.selectedCategories && this.possibleCategories && this.selectedCategories.length === this.possibleCategories.length;
        },

        iconCategories() {
            if (this.allCategories) {
                return 'mdi-check-box-outline';
            }
            if (this.someCategories) {
                return 'mdi-checkbox-blank-outline';
            }
            return 'mdi-checkbox-blank-circle-outline';
        },

    },
    watch: {

        userProfile: function () {
            if (this.userProfile.disciplines && this.userProfile.categories) {
                this.selectedDisciplines = this.userProfile.disciplines;
                this.selectedCategories = this.userProfile.categories;
            }
            this.loadUpdates();
        },

    },
    methods: {
        ...mapActions(["updateProfile",
            "toggleSubscription",
            "sendReferral",
            "toggleInterestingArticle",
            "getQueries",
            "getTopics",
            "sendReferralDashboard",
        ]),

        toggleSub() {
            this.toggleSubscription(this.userProfile);
        },

        saveProfile() {
            this.userProfile.disciplines = intersection(
                this.selectedDisciplines,
                this.uniqueDisciplines
            );
            this.userProfile.categories = intersection(
                this.selectedCategories,
                this.possibleCategories
            );

            this.updateProfile(this.userProfile);

            this.page = 1;

        },
        loadUpdates() {
            this.getTopics({
                page: this.page
            });
        },

        remove(item) {
            this.selectedCategories.splice(this.selectedCategories.indexOf(item), 1);
            this.selectedCategories = [...this.selectedCategories];
        },
        toggle() {

            this.$nextTick(() => {
                if (this.allCategories) {
                    this.selectedCategories = [];
                } else {

                    this.selectedCategories = this.possibleCategories;
                }
            })

        },

        toggleNewDiscipline() {
            const difDiscipline = this.selectedDisciplines.filter(x => !this.selectedDisciplinesOld.includes(x));

            let simpleQ = [];
            if (this.queries && this.queries.length > 0 && difDiscipline && difDiscipline.length > 0) {
                this.queries.forEach((query) => {
                    if (difDiscipline.includes(query.discipline)) {
                        simpleQ.push(query.query);
                    }
                });
            }
            this.selectedCategories.push(...simpleQ);

            this.selectedDisciplinesOld = this.selectedDisciplines;

        },

        sendReferralMail() {
            this.sendReferralDashboard({
                email: this.referralEmail,
            });
            this.referralEmail = "";
            this.referralSend = true;
        },

    },
};
</script>
