<template>
<div>
    <div v-if="!loading">
        <!-- form -->
        <!-- <v-form lazy-validation> -->
        <div class="container-sm mx-auto body-subscribe mb-12">
            <div class="row ml-1 mr-1">
                <div class="col align-self-center mt-1 mb-5">
                    <v-row>
                        <v-col offset=1>
                            <h5 class="offset-right-md-1">
                                <i>Subscribe to get weekly emails, our service is completely free to use!</i>
                                <!-- for the first three months. After this period, the service will stop automatically.
              If you wish to continue using RCTAlert after the free tryout period, you can select a payment option in the user menu (available after signup).</i> -->
                            </h5>
                        </v-col>
                    </v-row>
                    <div class="form-row">
                        <div class="col-1"></div>
                        <div class="col mb-2 mt-5">
                            <label for="id_first_name" class="label-subscription-form1 h3-custom3">First name:</label>
                        </div>
                        <div class="col offset-right-md-1 mb-2 mt-5">
                            <v-text-field v-model="firstName" label="First Name" :rules="nameRules" required></v-text-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-1"></div>
                        <div class="col mb-2">
                            <label for="id_last_name" class="label-subscription-form1 h3-custom3">Last name:</label>
                        </div>
                        <div class="col offset-right-md-1 mb-2">
                            <v-text-field v-model="lastName" label="Last Name" :rules="nameRules" required></v-text-field>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-1"></div>
                        <div class="col mb-2">
                            <label for="id_email" class="label-subscription-form1 h3-custom3">Email:</label>
                        </div>
                        <div class="col offset-right-md-1 mb-2">
                            <v-text-field v-model="email" label="Email" :rules="emailRules" required></v-text-field>
                        </div>
                    </div>

                    <!-- <div class="form-row">
          <div class="col-1"></div>
          <div class="col mb-2">
            <label for="id_email" class="label-subscription-form1 h3-custom3">
              Recommend to friend: <br> <span style="font-size: 14px !important;">(you and your friend get 3 extra months of free subscription)</span>
            </label>
          </div>
          <div class="col offset-right-md-1 mb-2">
            <v-text-field v-model="referralEmail" label="Referral email"></v-text-field>
          </div>
        </div> -->

                    <!-- fill in interests -->
                    <div class="form-row mb-5">
                        <div class="col-1"></div>
                        <div class="col mt-5 mb-1">
                            <label class="label-subscription-form1 h3-custom3">Your interests:</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-1"></div>

                        <div class="col mb-5">
                            <label class="my-1 mr-2 h5-custom">
                                specialty:
                            </label>

                            <v-select v-model="selectedDisciplines" color="primary" v-on:change="toggleNewDiscipline" :items="uniqueDisciplines" item-value="id" label="discipline" multiple chips deletable-chips></v-select>
                        </div>

                        <div class="col offset-right-md-1 mb-5">
                            <label class="my-1 mr-2 h5-custom">
                                disease:
                            </label>

                            <v-select v-model="selectedCategories" :items="possibleCategories" label="categories" multiple chips deletable-chips>

                                <!-- limit the amount of visible chips to max. of 7 -->
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index <= 6" close @click:close="remove(item)">
                                        <span>{{ item }}</span>
                                    </v-chip>
                                    <span v-if="index === 7" class="grey--text caption">
                                        (+{{ selectedCategories.length - 7 }} others)
                                    </span>
                                </template>

                                <!-- Add a tile that can select all diseases with custom methods-->
                                <v-list-item slot="prepend-item" ripple @click="toggle">

                                    <v-list-item-action>
                                        <v-icon :color="selectedCategories && selectedCategories.length > 0 ? 'indigo darken-4' : ''">{{ iconCategories }}</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-title>
                                        select all
                                    </v-list-item-title>

                                </v-list-item>

                                <v-divider slot="prepend-item" class="mt-2" />
                            </v-select>

                        </div>
                    </div>

                    <!-- check boxes -->

                    <div class="form-check">
                        <div class="col offset-md-1 mb-2">
                            <v-checkbox v-model="checkbox" :rules="[(v) => !!v || 'You must agree to continue!']" required></v-checkbox>
                            <label class="form-check-label label-subscription-form2" for="exampleCheck1">I agree with RCTAlert's
                                <a target="_blank" href="https://www.privacypolicygenerator.info/live.php?token=EKgL2jyz0REJTbLqf6B6pzBZOQ4t8Avw">
                                    <span style="color: #0AB1D2; text-decoration: underline;">privacy policy</span></a></label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col d-flex justify-content-center mt-5 mb-4">
                            <v-btn @click="register" type="submit" :disabled="!formIsValid" depressed>
                                Register
                            </v-btn>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-1"></div>
                        <div class="col text-center offset-right-md-1 mb-1">
                            <small id="emailHelp" class="form-text text-muted">After submission, you'll start receiving weekly RCT alerts
                                automatically until you unsubscribe.</small>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </v-form> -->
        </div>
    </div>
    <div v-else>
        Sending registration...
    </div>
</div>
<!-- </v-form> -->
</div>
</template>

<script>
import disciplines from "../lib/variables";
import {
    mapActions,
    mapState
} from "vuex";

export default {
    data() {
        return {
            loading: false,
            email: "",
            referralEmail: "",
            password1: "",
            password2: "",
            firstName: "",
            lastName: "",
            selectedDisciplines: [],
            selectedCategories: [],
            selectedQueries: [],
            selectedDisciplinesOld: [],
            disciplines,
            checkbox: false,
            nameRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
            ],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            passwordRules: [(v) => !!v || "Password is required"],
        };
    },
    computed: {
        ...mapState([
            "uniqueDisciplines",
            "queries",
        ]),
        possibleCategories: function () {
            let simpleQ = [];
            if (this.queries && this.queries.length > 0 && this.selectedDisciplines && this.selectedDisciplines.length > 0) {
                this.queries.forEach((query) => {
                    if (this.selectedDisciplines.includes(query.discipline)) {
                        simpleQ.push(query.query);
                    }
                });
            }
            return simpleQ;

        },
        categories() {
            const categories = new Set();
            disciplines
                .filter((discipline) =>
                    this.selectedDisciplines.includes(discipline.id)
                )
                .forEach((discipline) => {
                    (discipline.categories || []).forEach((cat) => categories.add(cat));
                });
            return [...categories];
        },
        formIsValid() {
            if (
                this.checkbox &&
                !!this.email &&
                !!this.firstName &&
                !!this.lastName
            ) {
                return true;
            } else {
                return false;
            }
        },
        allCategories() {
            return this.selectedCategories && this.possibleCategories && this.selectedCategories.length === this.possibleCategories.length;
        },

        iconCategories() {
            if (this.allCategories) {
                return 'mdi-check-box-outline';
            }
            if (this.someCategories) {
                return 'mdi-checkbox-blank-outline';
            }
            return 'mdi-checkbox-blank-circle-outline';
        },
    },
    methods: {
        ...mapActions([
            "getQueries",
        ]),
        register() {
            this.loading = true;
            this.$store.dispatch("signup", {
                email: this.email,
                referralEmail: "",
                firstName: this.firstName,
                lastName: this.lastName,
                disciplines: this.selectedDisciplines,
                categories: this.selectedCategories,
            });
        },
        remove(item) {
            this.selectedCategories.splice(this.selectedCategories.indexOf(item), 1);
            this.selectedCategories = [...this.selectedCategories];
        },
        toggle() {

            this.$nextTick(() => {
                if (this.allCategories) {
                    this.selectedCategories = [];
                } else {

                    this.selectedCategories = this.possibleCategories;
                }
            })
        },
        toggleNewDiscipline() {
            const difDiscipline = this.selectedDisciplines.filter(x => !this.selectedDisciplinesOld.includes(x));

            let simpleQ = [];
            if (this.queries && this.queries.length > 0 && difDiscipline && difDiscipline.length > 0) {
                this.queries.forEach((query) => {
                    if (difDiscipline.includes(query.discipline)) {
                        simpleQ.push(query.query);
                    }
                });
            }
            this.selectedCategories.push(...simpleQ);

            this.selectedDisciplinesOld = this.selectedDisciplines;
        },
    },
    mounted() {
        this.getQueries();

        this.referralEmail = this.$route.query.referralEmail && this.$route.query.referralEmail;

    },
};
</script>
