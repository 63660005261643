<template>
  <div>
     <!-- Top menu -->
<v-container>
  <v-row class='mb-2'style="padding-top: 1rem;">
      <div class="col">
        <div class="banner-text row">
          <nav class="navbar navbar-expand-lg navbar-light primary-color no-padding" style="width: 100% !important;">

            <div class="col-3">


              <!-- Navbar brand -->
              <router-link to="/" class="navbar-brand mt-1" >
                <img src="../assets/images/logo.png" width="232.2" height="48.75" alt="RCTAlert">
              </router-link>

            </div>

            <!-- if viewport > 575px -->
            <div class="col mt-2 collaps-button-right text-right">

              <!-- Collapse button -->
              <button class="navbar-toggler no-padding mb-5" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon mx-auto"></span>
              </button>

              <!-- Collapsible content -->
              <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav ml-auto">
                  <!-- <li class="mr-1 nav-item no-wrap">
                    <router-link to="browse" class="nav-link mb-2" >Browse RCTs<sup> beta</sup></router-link> 
                  </li> -->
                  <li class="mr-1 nav-item no-wrap ml-auto">
                    <div v-if="isLoggedIn">
                      <router-link to="logout" class="nav-link mb-2" >Logout</router-link> <!-- replace link -->
                    </div>
                    <div v-else>
                      <router-link to="login" class="nav-link mb-2" >Sign in</router-link> <!-- replace link -->
                    </div>
                  </li>
                  <div v-if="isLoggedIn" >
                    <li class="mr-1 nav-item no-wrap">
                      <router-link to="dashboard" class="nav-link mb-2" >Dashboard</router-link> <!-- replace link -->
                    </li>
                  </div>
                  <li class="mr-1 nav-item no-wrap ml-auto">
                    <router-link to="howitworks" class="nav-link mb-2" >How it works</router-link> <!-- replace link -->
                  </li>
                  <li class="mr-1 nav-item no-wrap ml-auto">
                    <router-link to="faq" class="nav-link mb-2">FAQ</router-link> <!-- replace link -->
                  </li>

                  <!-- subscribe button -->
                  <li v-if="!isLoggedIn" class="nav-item ml-3">
                    <router-link to="register" >
                      <!-- replace link -->
                      <button class="btn-subscribe" type="button" name="button">Subscribe</button>
                    </router-link>
                  </li>


                </ul>
              </div>

            </div>

            <!-- if viewport <575px -->
            <div class="col mt-2 my-auto collaps-button-left text-right">

              <!-- Collapse button -->
              <button class="navbar-toggler no-padding" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon mx-auto"></span>
              </button>

              <!-- Collapsible content -->
              <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav ml-auto">
                  <!-- <li class="mr-1 nav-item no-wrap">
                    <router-link to="browse" class="nav-link mb-2 mt-5" >Browse RCTs<sup> beta</sup></router-link> 
                  </li> -->
                  <li class="nav-item mr-1 no-wrap">
                    <router-link to="login" class="nav-link mb-2" >Sign in</router-link> <!-- replace link -->
                  </li>
                  <li class="nav-item mr-1 no-wrap ml-auto">
                    <router-link to="about" class="nav-link mb-2" >About</router-link> <!-- replace link -->
                  </li>
                  <li class="nav-item mr-1 no-wrap ml-auto">
                    <router-link to="howitworks" class="nav-link mb-2" >How it works</router-link> <!-- replace link -->
                  </li>
                  <!-- subscribe button -->
                  <li class="nav-item mr-1 ml-auto">
                    <router-link to="register">
                      <!-- replace link -->
                      <button class="btn-subscribe mt-2" type="button" name="button">Subscribe</button>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

          </nav>

        </div>
        <hr class="collaps-button-right" style="border: 0; height: 1px; background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(112, 193, 209, 1), rgba(0, 0, 0, 0));">
      </div>
    </v-row>
  </v-container>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  mounted() {
    //
  }
}
</script>
