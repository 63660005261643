<template>
<div>
<div>
  <img class="img-fluid img_img" src="../assets/images/admin.gif" alt="admin">
  <p>Number of users: {{ numUsers }}</p>
  <p>Number of referrals: {{ numReferrals }}</p>
  <p>!!THERE IS NO UNDO FEATURE!!</p>
  <p>Add a new email text to try. For enter use &lt;b&gt; </p>
  <p>email title: </p> 
  <input type="text" id="newTitle" >
  <p>email body: </p> 
  <input type="text" id="newBody" ><br>
  <button @click="addEmail">add</button>
  <p>after adding you can find the new query somewhere in the list (random order)</p>
</div>
<hr size="8" width="90%" color="red">  
<div v-for="(doc, index) in referralEmails" :key="index">
    <h4>{{ doc.emailTitle }}</h4>
    <p>{{ doc.emailBody }} </p>
    <p><b>email was sent to {{ doc.sendTo }} people and {{ (doc.response) }} registered - response = {{ ((doc.response)/doc.sendTo)*100 }}%</b></p>
    <button @click="deleteEmail(index)">delete email</button>
    <hr size="8" width="90%" color="red">  
    </div>
</div>
</template> 

<script>
import * as fb from '../lib/firebase'

export default {
  components: {
  },
  data() {
      return {
        referralEmails: [], 
        numUsers: 0, 
        numReferrals: 0,
      }
  },
  methods: {
    loadData() {
     axios
          .post(`https://us-central1-rctalert-api.cloudfunctions.net/app/graphql`, { 
            query: `{
              getReferralEmails {
                id
                emailBody
                emailTitle
                sendTo
                response
              }
            }`
          })
          .then((response) => {
            this.referralEmails = response.data.data.getReferralEmails;

          });

    },
    loadStats() {
      fb.referralCollection.get().then(snapshot => {
        this.numReferrals = snapshot.size;
      }); 
      fb.usersCollection.get().then(snapshot => {
        this.numUsers = snapshot.size;
      }); 
    },
    deleteEmail(index) {
      const doc = this.referralEmails[index];
      fb.referralEmailsCollection.doc(doc.id).delete().then(() => {
        this.loadData();
      });
    },
    addEmail() {
      const newTitle = document.getElementById('newTitle').value; 
      const newBody = document.getElementById('newBody').value;

      fb.referralEmailsCollection.add({
        emailTitle: newTitle,
        emailBody: newBody,
      }).then(() => {
        this.loadData();

      });
    }, 
  },
  mounted() {
    this.loadData();
    this.loadStats(); 
  },
}
</script>

<style scoped>
input {
  border: 1px solid black;
}
button {
  border: 1px solid red;
}
</style>