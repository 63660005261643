<template>
  <a :href="link" target="_blank">
    <img
      style="max-height: 16px; display: inline"
      src="../assets/images/openPDF.svg"
      alt="open PDF file"
    />
  </a>
</template>

<script>
export default {
  props: ["uid", "doi", "pmid"],
  computed: {
    link() {
      const link = new URL(
        "https://us-central1-rctalert-api.cloudfunctions.net/app/follow"
      );
      link.searchParams.append("uid", this.uid);
      link.searchParams.append("pmid", this.pmid);
      link.searchParams.append("doi", this.doi);

      return link.toString();
    },
  },
};
</script>