<template>
  <v-app>
  
  <Menu />

  <!-- website contents -->

      
    <router-view />


    
    <div class="footer-content background-footer">
      <div class="container">
        <div class="row">
          <div class="col my-auto">
            <img
              src="./assets/images/logo.png"
              style="height: 16px"
              alt="RCT Alert"
            />
          </div>
          <div class="col my-auto">
            <p
              class="text-center"
              style="
                margin: 0;
                font-family: 'Nunito', sans-serif;
                font-size: 0.75rem;
                font-weight: 300;
              "
            >
              &copy; RCTAlert, {{ currentYear }}. Developed by <a href="https://deepdoc.io/" target="_blank" style="color: #1717D9;">DeepDoc.</a> All rights reserved.
            </p>
          </div>
          <div class="col my-auto">
            <a target="_blank" href="https://twitter.com/rctalert?s=20" class="ml-3" style="float: right">
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/rctalert" class='ml-3' style="float: right;">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
          </div>
        </div>
      </div>
    </div>




  </v-app>
</template>



<style>
@import "./assets/css/styles.css";
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Nunito:wght@300;400&display=swap");
@import "./assets/css/bootstrap-social.css";
</style>


<script>
import Menu from './components/Menu.vue';

export default {
  data() {
    return {
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  metaInfo: {
    title: "RCTAlert",
  },
  components: {
    Menu,
  },
  data: () => ({
    //data
  }),
  mounted() {
  },
  methods: {
    //methods
  },
};
</script>