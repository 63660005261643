import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth } from '../lib/firebase'

import Home from '../views/Home.vue'
import FAQ from '../views/FAQ.vue'
import Dashboard from '../views/Dashboard.vue'
import Howitworks from '../views/Howitworks.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Reset from '../views/Reset.vue'
import Logout from '../views/Logout.vue'
import Pending from '../views/Pending.vue'
import Link from '../views/Link.vue'
import Development from '../views/Development.vue'
import Browse from '../views/Browse.vue'
import CheckoutSuccess from '../views/CheckoutSuccess.vue'
import Pico from '../views/Pico.vue'
import Dashboard2 from '../views/Dashboard2.vue'
import Dashboard3 from '../views/Dashboard3.vue'
import Dashboard4 from '../views/Dashboard4.vue'
import Admin from '../views/Admin.vue'
import ReferralAdmin from '../views/ReferralAdmin.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin', 
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true, 
      requiresAdmin: true,
    },
  },
  {
    path: '/referral', 
    name: 'referral',
    component: ReferralAdmin,
    meta: {
      requiresAuth: true, 
      requiresAdmin: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard4, 
    meta: {
      authRequired: false,
    },
  }, 
  {
    path: '/dashboard2',
    name: 'dashboard2',
    component: Dashboard2, 
    meta: {
      authRequired: true,
    },
  }, 
  // {
  //   path: '/dashboard3',
  //   name: 'dashboard3',
  //   component: Dashboard3, 
  //   meta: {
  //     authRequired: true,
  //   },
  // }, 
  {
    path: '/howitworks',
    name: 'howitworks',
    component: Howitworks,
  }, 
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/link', 
    name: 'link',
    component: Link,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  }, 
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  }, 
  {
    path: '/pending', 
    name: 'pending',
    component: Pending, 
  },
  {
    path: '/development',
    name: 'development',
    component: Development,
  },
  {
    path: '/browse',
    name: 'browse',
    component: Browse
  },
  {
    path: '/checkoutSuccess',
    name: 'checkoutSuccess',
    component: CheckoutSuccess
  }, 
  {
    path: '/pico',
    name: 'pico',
    component: Pico
  }
]

const router = new VueRouter({
  mode: 'history',
  routes, 
})

//check if needs to be logged in
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if(auth.currentUser && auth.currentUser.email.endsWith('@rctalert.com')) {
      next();
    } 
    else {
      next({
        path: 'login',
      });
    }
  }
  else if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      next({
        path: 'login',
      });
    }
  } else {
    next();
  }
});

export default router
