import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDTaSrWkUJMjOlYUaGToAnK072tQfkp_Wg",
  authDomain: "rctalert-api.firebaseapp.com",
  projectId: "rctalert-api",
  storageBucket: "rctalert-api.appspot.com",
  messagingSenderId: "50342661741",
  appId: "1:50342661741:web:b0351fda2a26f2cd71f964"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const usersCollection = db.collection('users');
const queryCollection = db.collection('queries');
const referralCollection = db.collection('referral');
const referralEmailsCollection = db.collection('referralEmails');

export { usersCollection, queryCollection,referralCollection,referralEmailsCollection, auth }