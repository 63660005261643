import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../lib/firebase'
import router from '../router/index'

const generator = require('generate-password');

Vue.use(Vuex)

const page_size = 5; // cannot be more than 10 

const config = {
  apiBaseUrl: process.env.NODE_ENV === 'production' ? 'https://us-central1-rctalert-api.cloudfunctions.net/app' : 'http://localhost:5001/rctalert-api/us-central1/app',
  clientBaseUrl: process.env.NODE_ENV === 'production' ? 'https://rctalert.com' : 'http://localhost:8080',
};

//for db dev
//const config = {
//  apiBaseUrl: process.env.NODE_ENV === 'production' ? 'https://us-central1-rctalert-api.cloudfunctions.net/app' : 'http://localhost:5000',
//  clientBaseUrl: process.env.NODE_ENV === 'production' ? 'https://rctalert-api.web.app' : 'http://localhost:8080',
//};

//for ui dev
// const config = {
//   apiBaseUrl: process.env.NODE_ENV === 'production' ? 'https://us-central1-rctalert-api.cloudfunctions.net/app' : 'http://localhost:5001/rctalert-api/us-central1/app',
//   clientBaseUrl: process.env.NODE_ENV === 'production' ? 'https://rctalert.com' : 'http://localhost:8080',
// };

// // for ui dev philly
// const config = {
//   apiBaseUrl: 'https://us-central1-rctalert-api.cloudfunctions.net/app',
//   clientBaseUrl: process.env.NODE_ENV === 'production' ? 'https://rctalert.com' : 'http://localhost:8080',
// };

export default new Vuex.Store({
  state: {
    userProfile: {},
    docs: [],
    docsReviews: [],
    numDocs: 0, 
    numDocsReviews: 0,
    loadingRcts: true,
    loadingReviews: true, 
    loadingSearch: false,
    docsSearch: [],
    numDocsSearch: 0,

    docsUpdates: [],
    loadingUpdates: false, 
    numDocsUpdates: 0,
    numPagesUpdates: 0,
    uniqueDisciplines: [], 
    uniqueCategories: [],
    queries: {},
  },
  getters: {
    isLoggedIn: state => Boolean(state.userProfile && state.userProfile.uid)
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setDocs(state, docs) {
      state.docs = docs;
    },
    setDocsReviews(state, docs){
      state.docsReviews = docs;
    },
    setNumDocs(state, numDocs) {
      state.numDocs = numDocs;
    }, 
    setNumDocsReviews(state, numDocs) {
      state.numDocsReviews = numDocs;
    }, 
    setLoadingRcts(state, loading) {
      state.loadingRcts = loading; 
    },
    setLoadingReviews(state, loading) {
      state.loadingReviews = loading; 
    },

    setDocsSearch(state, docs) {
      state.docsSearch = docs;
    },
    setNumDocsSearch(state, numDocs) {
      state.numDocsSearch = numDocs;
    },
    setLoadingSearch(state, loading) {
      state.loadingSearch = loading; 
    },

    setDocsUpdates(state, payload) {
      state.docsUpdates = payload; 
    },
    setLoadingUpdates(state, payload) {
      state.loadingUpdates = payload;
    },
    setNumDocsUpdates(state, payload) {
      state.numDocsUpdates = payload;
    },
    setNumPagesUpdates(state, payload) {
      state.numPagesUpdates = payload;
    },
    setUniqueDisciplines(state, payload) {
      state.uniqueDisciplines = payload;
    }, 
    setQueries(state, payload) {
      state.queries = payload;
    },

  }, 
  actions: {
    /////////////////
    // User functions
    /////////////////
    // async login({ dispatch }, form) {
    //   const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password);
      
    //   dispatch('fetchUserProfile', user);
    // },

    //link login 
    async loginWithLink({dispatch}, form) {
      var actionCodeSettings = {
        url: `${config.clientBaseUrl}/link`,
        handleCodeInApp: true,
      };
      
      fb.auth.fetchSignInMethodsForEmail(form.email)
        .then((signInMethods) => {

          if (signInMethods.length > 0) {
            fb.auth.sendSignInLinkToEmail(form.email, actionCodeSettings)
              .then(() => {
                window.localStorage.setItem('emailForSignIn', form.email);
                router.push('/pending');
              })
          } else {
            router.push('/register');
          }
        })
    },
    
    //validate login link
    async validateLink({dispatch}, form) {
      await fb.auth.signInWithEmailLink(form.email, window.location.href)
        .catch((error) =>{
          dispatch('loginWithLink', form);
        })

      window.localStorage.removeItem('emailForSignIn');

      router.push('/dashboard');
    },

    //fetch user profile 
    async fetchUserProfile({commit}, user){
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      const userProfileData = userProfile.data();

      // if(userProfileData && userProfileData.email.endsWith("@rctalert.com") ){
      //   userProfileData.isAdmin = true;
      // } else {
      //   userProfileData.isAdmin = false;
      // }

      commit('setUserProfile', userProfileData);

    },

    //signup new user
    async signup({dispatch}, form) {

      const password = generator.generate({
        length: 10,
        numbers: true
      });

      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, password)
        .catch(error => {
          window.alert(error.message);
        });

      var actionCodeSettings = {
        url: `${config.clientBaseUrl}/dashboard`,
        handleCodeInApp: true,
      };

      await user.sendEmailVerification(actionCodeSettings);

      const freeMonths = form.referralEmail ? 6 : 3; 

      const d = new Date();
      d.setMonth(d.getMonth()+freeMonths);
      
      await fb.usersCollection.doc(user.uid).set({
        uid: user.uid,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        disciplines: form.disciplines,
        categories: form.categories,
        referralEmail: form.referralEmail ? form.referralEmail : "",
        subscriptionUntil: d,
        hasSubscription: false,
        alert: true,
      });

      // this.$gtag.event('register', { method: 'RCTAlert form' });

      dispatch('fetchUserProfile', user);

      router.push('/dashboard').catch(()=>{});

    }, 
    async updateProfile({dispatch}, user) {
      await fb.usersCollection.doc(user.uid).set(user);

      dispatch('fetchUserProfile', user);
    },
    async logout({commit}) {
      await fb.auth.signOut();

      commit('setUserProfile', {});

      router.push('/login');
    },
    async toggleSubscription({dispatch}, user) {
      user.alert = !user.alert;

      dispatch('updateProfile', user);
    },
    /////////////////
    // Content functions
    /////////////////
    //load docs for rcts
    async loadDocs({commit}, {selectedCategories, selectedDisciplines, timeDelta, page, type}) {
      if(type === "rct") {
        commit('setLoadingRcts', true);
      } else if(type === "review") {
        commit('setLoadingReviews', true);
      }
      axios
        .post(`${config.apiBaseUrl}/api/v1/queryResearch`, { 
          discipline: selectedDisciplines,
          category: selectedCategories,
          delta: timeDelta,
          publicationType: type,
          page_size: page_size,
          page: page,
        })
        .then((response) => {
          const { docs, _size } = response.data;
          if(type === "rct") {
            commit('setDocs', docs.map((doc) => {
              if(typeof doc.selected == "undefined") {
                doc.selected = false;
              }
              doc.robTotal =
                (doc.rob1 || 0) +
                (doc.rob2 || 0) +
                (doc.rob3 || 0) +
                (doc.rob4 || 0) +
                (doc.rob5 || 0);

              if(doc.robTotal < 5 && doc.impact_factor > 5) {
                doc.robTotal = 4;
              }
              if(doc.robTotal < 5 && doc.impact_factor > 10) {
                doc.robTotal = 5;
              }
              return doc;
            }))
            commit('setNumDocs', Math.ceil(_size / page_size));

          } else if(type === "review") {
            commit('setDocsReviews', docs.map((doc) => {
              //any analysis
              if(typeof doc.selected == "undefined") {
                doc.selected = false;
              }
              return doc;
            }))
            commit('setNumDocsReviews', Math.ceil(_size / page_size))
          }
          if(type === "rct") {
            commit('setLoadingRcts', false);
          } else if(type === "review") {
            commit('setLoadingReviews', false);
          }
        })
      },   

      //load docs for search
      async search({commit}, {search_term_p, search_term_i, search_term_c, search_term_o, page, sort_by, sort_order, fuzziness}) {

        commit('setLoadingSearch', true);

        axios
          .post(`${config.apiBaseUrl}/api/v1/searchPico`, { 
            search_term_p: search_term_p,
            search_term_i: search_term_i,
            search_term_c: search_term_c,
            search_term_o: search_term_o,
            page_size: page_size,
            page: page,
            sort_by: sort_by,
            sort_order: sort_order,
            fuzziness: fuzziness,
          })
          .then((response) => {
  
            const { docs, _size } = response.data;
  
            commit('setDocsSearch', docs);

            commit('setNumDocsSearch', Math.ceil(_size / page_size));
              
            commit('setLoadingSearch', false);

          })
      },
      async toggleInterestingArticle({}, {pmid, selected}){

        axios
          .post(`${config.apiBaseUrl}/api/v1/toggleInterestingArticle`, { 
            pmid: pmid,
            selected: selected,
          })
      },
      // send referral email
      async sendReferral({}, {referralEmail}) {
        axios
          .post(`${config.apiBaseUrl}/api/v1/sendReferralEmail`, { 
            referralEmail: referralEmail,
            referrerEmail: this.state.userProfile.email,
          })
      },

      



      async getTopics({commit}, {page}) {

        commit('setLoadingUpdates', true);

        let topics = [];

        if (this.state.queries && this.state.queries.length > 0 && this.state.userProfile) {

            this.state.queries.forEach((query) => {
                if (this.state.userProfile.categories.includes(query.query) & this.state.userProfile.disciplines.includes(query.discipline)) {
                    query.topics.forEach((topic) => {
                        topics.push(topic);
                    });
                }
            });
        }

        axios
          .post(`${config.apiBaseUrl}/graphql`, { 
            query: `{
              getUpdates(topics: ["${topics.join('", "')}"], page: ${page}, page_size: ${page_size}) {
                  docs {
                  pmid
                  title
                  abstract
                  jabbrv
                  date
                  impact_factor
                  author
                  doi
                  p
                  i
                  c
                  o
                  b
                  n
                }
                size
                page
                page_size
              }
            }`
          })
          .then((response) => {
            let { docs, size } = response.data.data.getUpdates;

            docs.map(doc => {
              doc.selected = false;
              return doc;
            });
  
            commit('setDocsUpdates', docs);

            if(size > 1000) {
              size = 1000; 
            }

            commit('setNumPagesUpdates', Math.ceil(size / page_size));
            commit('setNumDocsUpdates', size);
              
            commit('setLoadingUpdates', false);

          });
      },
      async getQueries({commit, dispatch}) {

        commit('setLoadingUpdates', true);

        commit('setDocsUpdates', []);
        commit('setNumDocsUpdates', 0);
        commit('setNumPagesUpdates', 0);
        
        axios
          .post(`${config.apiBaseUrl}/graphql`, { 
            query: `{
              getAllQueries {
                docs {
                  discipline
                  query
                  topics
                }
              } 
            }`
          })
          .then((response) => {
            let { docs } = response.data.data.getAllQueries;
            let uniqueDisciplines = [];
            docs.forEach(doc => {
              if(!uniqueDisciplines.includes(doc.discipline)) {
                uniqueDisciplines.push(doc.discipline);
              }
            });

            commit('setUniqueDisciplines', uniqueDisciplines);
            commit('setQueries', docs);
            commit('setLoadingUpdates', false);

            dispatch('getTopics', {page: 1});

          });
      },

      async sendReferralDashboard(_, {email}) {

        axios
          .post(`${config.apiBaseUrl}/graphql`, { 
            query: `{
              sendReferral(email: "${email}") 
            }`
          }).catch(console.error);

      },

  }

})
