<template>
  <div>

    <!-- form -->
<div class="container-sm" style="height: 100vh;">
    <div class="row ml-1 mr-1">
      <div class="col align-self-center mt-1 mb-5">
          <div class="form-row">
            <div class="col-1">
            </div>
            <!-- <div class="col mb-2 mt-5">
              <label for="id_username" class="label-subscription-form1">email:</label>
            </div> -->
            <div class="col offset-right-md-1 mb-2 mt-5">

              <v-text-field
                v-model="email"
                label="Email"
                required
                :rules="emailRules"
                v-on:keydown.enter.prevent='login'
              ></v-text-field>

            </div>
          </div>



<!-- 
           <div class="form-row">
            <div class="col-1">
            </div>
   
            <div class="col offset-right-md-1 mb-2"> 

              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                required
              ></v-text-field>

            </div>
          </div>  -->




          <div class="form-row">
            <div class="col d-flex justify-content-center mt-5 mb-4">
  
              <v-btn 
                @click="login"
                depressed 
                :disabled="!valid"
              >
              Send Login Link
              </v-btn>

            </div>
          </div>
          <div class="form-row">
            <div class="col-1">
  
            </div>
            <div class="col text-center offset-right-md-1 mb-1">
              <small id="emailHelp" class="form-text text-muted"><p>Or <router-link to="register">register</router-link> if you don't have an account. </p></small>
              <!-- If you forgot your password you can reset it <router-link to="reset">here</router-link>. -->
            </div>
          </div>
      </div>
    </div>
  </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      valid: true,
    }
  },
  methods: {
    login() {
      this.$store.dispatch('loginWithLink', { //loginWithLink or login
        email: this.email,
      })
    }
  }
};
</script>
