<template>
  <div>


<v-container>
  <v-row>
<p class='p-custom-about mb-10 px-10 text-justify'>
  <!-- Our machine learning models are able to extract PICO-terms from RCTs. This means you are able to browse all RCTs very specifically by just entering some keywords. We can directly crossreference your keywords with extracted PICO labels from our database. Instead of going through the tedious process of designing a PubMed query, this allows you to get specific query results fast and simple.
  <br> <br>
  <i>
  This is a beta feature that currently only supports RCTs from the last year with a general PICO search (searching all PICO labels). We are working on a complete RCT-PICO database that you can search through, also by specifying PICO terms separately to further tailor your results.
  </i> -->
</p>
  </v-row>
</v-container>

<!-- search bar for any terms -->
<!-- <v-form>
  <v-container>
    <v-row>
      <v-text-field class="px-10 mt-10 mb-1"
      solo
      label="P"
      clearable
      v-model="search_term_p"
      v-on:keydown.enter.prevent='newSearch'
      ></v-text-field>
    </v-row>
  </v-container>

  <v-container>
    <v-row>
      <v-text-field class="px-10 mt-10 mb-1"
      solo
      label="I"
      clearable
      v-model="search_term_i"
      v-on:keydown.enter.prevent='newSearch'
      ></v-text-field>
    </v-row>
  </v-container>

</v-form> -->

<!-- expandable PICO search bars for specifying P, I, C and/or O -->

<v-container class='px-10'>
<!-- <v-expansion-panels
  expand 
    >
      <v-expansion-panel :value="0">
        <v-expansion-panel-header class= "v-label theme--light" style="padding-left:12px;"> -->
          <!-- Define PICO -->
          <!-- </v-expansion-panel-header>
        <v-expansion-panel-content :value="0"> -->

         <v-form>
    <v-container>
      <v-row class='px-10'>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Population"
            v-model="search_term_p"
            v-on:keydown.enter.prevent='newSearch'
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Intervention"
            v-model="search_term_i"
            v-on:keydown.enter.prevent='newSearch'
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Control"
            v-model="search_term_c"
            v-on:keydown.enter.prevent='newSearch'
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Outcome"
            v-model="search_term_o"
            v-on:keydown.enter.prevent='newSearch'
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
        :items="sort_options"
        label="sort by"
        v-model="sort_by"
      ></v-select>
          </v-col>
          <v-col>
          <v-select
        :items="sort_order_options"
        label="sort order"
        v-model="sort_order"
      ></v-select>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          Fuzziness (how tolerent to error should the search be): {{ fuzziness }}
          <v-slider
            v-model="fuzziness"
            :min="0"
            :max="2"
            :step="0.1"
            :thumb-label="false"
            :hide-details="true"
            color="primary" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>

        <!-- </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    </v-container> 
   




<v-container class="text-center mb-10">
  <v-btn 
  elevation="2"
  @click="newSearch"
>Search</v-btn>
</v-container>

<v-container class="px-10">
      <div class="background-search-results mb-10">
        <div class="row ml-2 mt-2">
          <h5 class="h5-custom" style="color: #4f4f4f; margin: 2.083333% 0">
            Matched RCTs:
          </h5>
        </div>

        <!-- sample size header -->
        <div class="row ml-2">
          <div class="col-8"></div>
          <div class="col-2">
            <p class="p-custom-dashboard4 RoB-header">
              <b>Estimated RCT Quality:</b>
            </p>
          </div>
          <div class="col-sm-2">
            <p class="p-custom-dashboard4 sample-size-header">
              <b>Sample Size:</b>
            </p>
          </div>
        </div>

        <div v-if="loadingSearch">
          <center>Loading...</center>
        </div>
        <div v-else>
          <div v-if="numDocsSearch === 0">
            <center>No papers found...</center>
          </div>

        <div v-for="doc in docsSearch" :key="doc.pmid">
          <!-- article  -->

          <!-- labels  -->
          <div class="row mt-3 ml-2">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-10">
              <div class="row">
                <p class="p-custom-dashboard1">
                  <font-awesome-icon :icon="['fas', 'tag']" />
                  <b> {{ doc.discipline }}</b>
                  <span>: </span><span>{{ doc.category.join(", ") }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- date and journal  -->
          <div class="row ml-2">
            <div class="col-8">
              <div class="row">
                <p class="p-custom-dashboard1">
                  {{ doc.date | formatFirebaseDate }}
                </p>
                <p class="p-custom-dashboard1">|</p>
                <p class="p-custom-dashboard1">{{ doc.jabbrv }}</p>
              </div>
            </div>

            <!-- sample size  -->
            <div class="col">
              <div class="row mx-auto">
                <p class="no-header-text p-custom-dashboard-sample-size">
                  Size =
                  <span>
                    {{ doc.n || "?" }}
                  </span>
                </p>
                <!-- insert sample size number here (placeholder #) -->
              </div>
            </div>
          </div>

          <!-- title of paper  -->
          <div class="row ml-2 sm-mr-2">
            <div class="col-lg-7 col-md-7 col-sm-8 col-xs-12">
              <div class="row">
                <!-- <a target="_blank" href=""> -->
                  <p class="p-custom-dashboard2">{{ doc.title }}</p>
                <!-- </a> -->
              </div>
            </div>

            <!-- pubmed url  -->
            <div class="col-1 no-padding">
              <tracking-link :uid=fromUser :doi=doc.doi :pmid=doc.pmid />
            </div>

            <!-- RoB STARS ON FULL LG SCREEN -->
            <div class="col-lg-2 col-md col-sm">
              <div class="row RoB-header">
                <div class="col-12 text-right">
                  <ul class="no-padding">
                    <li
                      style="display: inline-block"
                      v-for="i in [1, 2, 3, 4, 5]"
                      :key="i"
                    >
                      <img
                        src="../assets/images/star-full.svg"
                        v-if="i <= doc.robTotal"
                        width="16"
                        alt="1"
                      />
                      <img
                        src="../assets/images/star-empty.svg"
                        v-else
                        width="16"
                        alt="1"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-2">
              <p class="p-custom-dashboard-sample-size">
                <span class="with-header-text">
                  {{ doc.n || "?" }}
                </span>
                <!-- insert sample size number here (placeholder #) -->
              </p>
            </div>
          </div>

          <!-- author  -->
          <div class="row ml-2">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <div class="row">
                <p class="p-custom-dashboard3 no-padding">{{ doc.author }}</p>
              </div>
            </div>

            <div class="col-sm-0"></div>

            <div class="col-sm-0"></div>
          </div>

          <!-- RoB STARS ON SMALLER SCREEN -->
          <div class="row ml-2">
            <p class="no-header-text2 p-custom-dashboard-sample-size">
              Estimated RCT Quality:
            </p>
            <ul class="no-padding no-header-text2" style="float: right">
              <li
                style="display: inline-block"
                v-for="i in [1, 2, 3, 4, 5]"
                :key="i"
              >
                <img
                  src="../assets/images/star-full.svg"
                  v-if="i <= doc.robTotal"
                  width="14"
                  alt="1"
                />
                <img
                  src="../assets/images/star-empty.svg"
                  v-else
                  width="14"
                  alt="1"
                />
              </li>
            </ul>
          </div>

          <!-- abstract  -->
          <div class="row mb-3 ml-2 sm-mr-2">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="row">
                <!-- <p
                  class="p-custom-dashboard1 text-justify"
                  v-if="doc.b"
                >
                  <b>Extracted summary:</b>
                  {{ doc.b }}
                  <!-- | truncate(500, "...") }} 
                </p> -->

                <p class="p-custom-dashboard1 text-justify" > <!-- v-else -->
                  <b>Title + Abstract:</b> 
                  

                  <div v-html="span(highlight(doc.title + ' ' + doc.abstract, doc.p, doc.i, doc.c, doc.o, doc.b))" />

                  <!-- | truncate(500, "...") }} -->
                </p>
              </div>
            </div>
            <div class="col-lg col-md"></div>
          </div>

          <!-- <PICO :p=doc.p :i=doc.i :c=doc.c :o=doc.o v-if="doc.p || doc.i || doc.c || doc.o"></PICO> -->
          <span class="patient">P</span>-
          <span class="intervention">I</span>-
          <span class="control">C</span>-
          <span class="outcome">O</span>-
          <span class="bottomline">bottom line</span>
          <hr />
          
          <!-- END ARTICLE -->
        </div>
        </div>

        

        <!-- Paginator -->
        <div class="row">
          <div class="col-12 ml-2">
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="numDocsSearch"
                @input="loadSearch"
                :total-visible="7"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
</v-container>


</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TrackingLink from "../components/TrackingLink";
import PICO from "../components/PICO.vue";

export default {
  components: {
    TrackingLink,
    PICO
  },
  data() {
    return {
      fromUser: "browse",
      search_term_p: "",
      search_term_i: "",
      search_term_c: "",
      search_term_o: "",
      sort_by: "impact_factor", 
      sort_options: ["n", "year", "impact_factor"],
      sort_order_options: ["asc", "desc"],
      sort_order: "desc",
      page: 1,
      fuzziness: 1,
      expanded: true,
    };
  },
  mounted() {
  },
  computed: {
    ...mapState([
      "docsSearch",
      "numDocsSearch",
      "loadingSearch",
    ]),
  },
  methods: {
    ...mapActions(["search"]),
    span(text) {
    return `<span> ${text} </span>`
   },
  highlight(text, p, i, c, o, b) {
    b.split(" ; ").forEach(t => {
      text = text.replace(t, `<span class="bottomline">${t}</span>`);
    });

    p.split(" ; ").forEach(t => {
      text = text.replace(t, `<span class="patient">${t}</span>`);
    });

    i.split(" ; ").forEach(t => {
      text = text.replace(t,`<span class="intervention">${t}</span>`);
    });

    c.split(" ; ").forEach(t => {
      text = text.replace( t,`<span class="control">${t}</span>`);
    });

    o.split(" ; ").forEach(t => {
      text = text.replace(t, `<span class="outcome">${t}</span>`);
    });

    return text;
    //text = text.replace(p, '<mark>' + query + '</mark>');
  },
    newSearch() {
      this.page = 1;
      this.search({
        page: this.page,
        sort_by: this.sort_by,
        sort_order: this.sort_order,
        fuzziness: this.fuzziness,
        search_term_p: this.search_term_p.toLowerCase(),
        search_term_i: this.search_term_i.toLowerCase(),
        search_term_c: this.search_term_c.toLowerCase(),
        search_term_o: this.search_term_o.toLowerCase(),
      });

    },

    loadSearch() {
      this.search({
        page: this.page,
        sort_by: this.sort_by,
        sort_order: this.sort_order,
        fuzziness: this.fuzziness,
        search_term_p: this.search_term_p.toLowerCase(),
        search_term_i: this.search_term_i.toLowerCase(),
        search_term_c: this.search_term_c.toLowerCase(),
        search_term_o: this.search_term_o.toLowerCase(),
      });
    },

  },
};
</script>

<style>
.patient {
  background: rgb(253, 147, 147) !important;
  color: white !important;
}
.intervention {
  background: rgb(142, 178, 233) !important;
  color: white !important;
}
.control {
  background: rgb(167, 240, 200) !important;
  color: white !important;
}
.outcome {
  background: rgb(161, 233, 243) !important;
  color: white !important;
}
.bottomline {
  background: rgb(243, 162, 232) !important;
  color: white !important;
}
</style>