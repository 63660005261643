import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

const connector = new AppSearchAPIConnector({
  searchKey: "search-1qdmmemk8eahd32yw6e781gs",
  engineName: "pico-search",
  endpointBase: "https://pico-search.ent.us-central1.gcp.cloud.es.io",
  cacheResponses: false
});

const config = {
  debug: true,
  apiConnector: connector,
  searchQuery: {
    disjunctiveFacets: ["p"],
    facets: {
      p: {
        type: "value"
      },
    }
  }
};

export default config;