<template>
<div>
  <!-- Salutation -->
  <div class="settings-bar">
    <div class="row ml-2 mr-2 mb-3 ">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <h5 class="mb-5">
          Welcome <span>{{ userProfile && userProfile.firstName }}</span>!

          <!-- <div v-if="userProfile && !userProfile.hasSubscription">

            You have a free subscription until: {{ userProfile && userProfile.subscriptionUntil | formatDate }} <br />
            refer a colleague to get 3 months extra free subscription: 

            </div>
            <div v-else>
              You currently have an active subscription.  <br />

              Do you like RCTAlert? Help us by referring a colleague: 
            </div>
            
              <v-text-field
                v-model="referralEmail"
                label="email"
              ></v-text-field>
              <v-btn
                @click="sendReferralPressed"
              >
                refer
              </v-btn>
              <div v-if="referralSend">
                Referral email was send to: {{ referralEmail }}
              </div>

              <br />

              <div v-if="userProfile && !userProfile.hasSubscription && userProfile.stripeId">
                <form :action="baseURL + '/api/v1/create-checkout-session'" method="POST">
                  <input type="hidden" name="priceId" value="price_1JEwPPJnqKATIikXn4s67cdl" />
                  <input type="hidden" name="stripeId" :value="userProfile.stripeId" />
                  <v-btn type="submit">Buy subscription</v-btn>
                </form>
              </div>

              <div v-if="userProfile && userProfile.hasSubscription">
                <form :action="baseURL + '/api/v1/customer-portal'" method="POST">
                  <input type="hidden" name="stripeId" :value="userProfile.stripeId" />
                  <v-btn type="submit">Manage subscription</v-btn>
                </form>
              </div> -->

          <br />
          <span style="font-size: 14px">Below we lined up this week's articles for you.</span>
        </h5>
      </div>

      <!-- Settings: change categories -->
      <div class="col-lg-6 col-md-12 col-sm-12 text-lg-right">
        <v-select v-model="selectedDisciplines" :items="disciplines" v-on:change="toggleNew" item-text="label" item-value="id" label="discipline" multiple chips deletable-chips></v-select>
        <!-- this.userProfile.disciplines ? this.userProfile.disciplines : [] -->
        <v-select v-model="selectedCategories" :items="categories" item-text="label" item-value="id" label="categories" multiple chips deletable-chips>

          <!-- limit the amount of visible chips to max. of 7 -->
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 6" close @click:close="remove(item.id)">
              <span>{{ item.label }}</span>
            </v-chip>
            <span v-if="index === 7" class="grey--text caption">
              (+{{ selectedCategories.length - 7 }} others)
            </span>
          </template>

          <!-- Add a tile that can select all diseases with custom methods-->
          <v-list-item slot="prepend-item" ripple @click="toggle">

            <v-list-item-action>
              <v-icon :color="selectedCategories && selectedCategories.length > 0 ? 'indigo darken-4' : ''">{{ iconCategories }}</v-icon>
            </v-list-item-action>

            <v-list-item-title>
              select all
            </v-list-item-title>

          </v-list-item>

          <v-divider slot="prepend-item" class="mt-2" />
        </v-select>

        <v-btn @click="saveProfile">
          Save
        </v-btn>

      </div>
    </div>
  </div>

  <!-- This week's RCTs -->
  <div class="container-fluid mb-5 dashboard">
    <div class="background-dashboard">
      <div class="row ml-2 mt-2">
        <h3 class="h3-custom" style="color: #4f4f4f; margin: 2.083333% 0">
          This week's RCTs
        </h3>
      </div>

      <!-- sample size header -->
      <div class="row ml-2">
        <div class="col-8"></div>
        <div class="col-2">
          <p class="p-custom-dashboard4 RoB-header">
            <b>Estimated RCT Quality:</b>
          </p>
        </div>
        <div class="col-sm-2">
          <p class="p-custom-dashboard4 sample-size-header">
            <b>Sample Size:</b>
          </p>
        </div>
      </div>

      <div v-if="loadingRcts">
        <center>Loading...</center>
      </div>
      <div v-else>
        <div v-if="numDocs === 0">
          <center>No papers found...</center>
        </div>

        <div v-for="doc in docs" :key="doc.pmid">
          <!-- article  -->

          <!-- labels  -->
          <div class="row mt-3 ml-2">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-10">
              <div class="row">
                <p class="mb-3 p-custom-dashboard1">
                  <font-awesome-icon :icon="['fas', 'tag']" />
                  <b> {{ doc.discipline | formatFirebaseDiscipline }}</b>
                  <span>: </span><span>{{ doc.category | formatFirebaseCategory }}</span><br>
                </p>
              </div>
            </div>
          </div>

          <!-- date and journal  -->
          <div class="row ml-2">
            <div class="col-8">
              <div class="row">
                <p class="p-custom-dashboard1">
                  {{ doc.date | formatFirebaseDate }}
                </p>
                <p class="p-custom-dashboard1">|</p>
                <p class="p-custom-dashboard1">{{ doc.jabbrv }}</p>
              </div>
            </div>

            <!-- sample size  -->
            <div class="col">
              <div class="row mx-auto">
                <p class="no-header-text p-custom-dashboard-sample-size">
                  {{ doc.sampleSize === undefined ? "" : "Size = ", }}
                  <span>
                    {{ doc.sampleSize || "" }}
                  </span>
                </p>
                <!-- insert sample size number here (placeholder #) -->
              </div>
            </div>
          </div>

          <!-- title of paper  -->
          <div class="row ml-2 sm-mr-2">
            <div class="col-lg-7 col-md-7 col-10">
              <div class="row">
                <!-- <a target="_blank" href=""> -->
                <p class="p-custom-dashboard2">{{ doc.title }}</p>
                <!-- </a> -->
              </div>
            </div>

            <!-- pubmed url  -->
            <div class="col-lg-1 col-md-1 col-2">
              <tracking-link :uid=userProfile.uid :doi=doc.doi :pmid=doc.pmid />
            </div>

            <!-- RoB STARS ON FULL LG SCREEN -->
            <div class="sample-size-header col-lg-2 col-md col-sm">
              <div class="row RoB-header">
                <div class="col-12 text-right">
                  <ul class="no-padding">
                    <li style="display: inline-block" v-for="i in [1, 2, 3, 4, 5]" :key="i">
                      <img src="../assets/images/star-full.svg" v-if="i <= doc.robTotal" width="16" alt="1" />
                      <img src="../assets/images/star-empty.svg" v-else width="16" alt="1" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="sample-size-header col-2 col-sm-0">
              <p class="p-custom-dashboard-sample-size">
                <span class="with-header-text">
                  {{ doc.sampleSize || "" }}
                </span>
                <!-- insert sample size number here (placeholder #) -->
              </p>
            </div>
          </div>

          <!-- author  -->
          <div class="row ml-2 no-padding">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <div class="row">
                <p class="p-custom-dashboard3 no-padding">{{ doc.author }}</p>
              </div>
            </div>

            <div class="col-sm-0"></div>

            <div class="col-sm-0"></div>
          </div>

          <!-- RoB STARS ON SMALLER SCREEN -->
          <div class="row ml-2">
            <p class="no-header-text2 p-custom-dashboard1 mb-2">
              <b>Estimated RCT Quality:</b>
            </p>
            <ul class="mb-lg-0 mb-5 no-padding no-header-text2" style="float: right">
              <li style="display: inline-block" v-for="i in [1, 2, 3, 4, 5]" :key="i">
                <img src="../assets/images/star-full.svg" v-if="i <= doc.robTotal" width="14" alt="1" />
                <img src="../assets/images/star-empty.svg" v-else width="14" alt="1" />
              </li>
            </ul>
          </div>

          <!-- abstract  -->
          <div class="row mt-2 mb-3 ml-2 sm-mr-2">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="row">
                <p class="p-custom-dashboard1 text-justify" v-if="doc.bottomLine">
                  <b>Extracted summary:</b>
                  {{ doc.bottomLine | truncate(500, "...") }}
                </p>

                <p class="p-custom-dashboard1 text-justify" v-else>
                  <b>Abstract:</b> {{ doc.abstract | truncate(500, "...") }}
                </p>
                <div v-if="userProfile && userProfile.isAdmin">
                  <p><b>admin:</b></p>
                  <v-checkbox color="info" v-model=doc.selected class="" @change="toggleInterestingArt(doc.pmid, doc.selected)">
                  </v-checkbox>
                  <p>predicted discipline: {{ doc.classified_discipline }}</p>
                  <p>impact factor: {{ doc.impact_factor }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg col-md"></div>
          </div>

          <!-- PICO -->
          <PICO :p=doc.p :i=doc.i :c=doc.c :o=doc.o v-if="doc.p || doc.i || doc.c || doc.o"></PICO>
          <hr />

          <!-- END ARTICLE -->
        </div>
      </div>

      <!-- Paginator -->
      <div class="row">
        <div class="col-12 ml-2">
          <div class="text-center">
            <v-pagination v-model="page" :length="numDocs" @input="loadPage" :total-visible="7"></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This week's RCTs -->
  <!--
<div class="container-fluid mb-5 dashboard">
  <div class="background-dashboard">
    <div class="row ml-2 mt-2">
      <h3 class="h3-custom" style="color: #4F4F4F; margin: 2.083333% 0;">This week's RCTs</h3>
    </div>
    <div class="row ml-2">
      <p class="p-custom-dashboard1">No new RCTs were found...</p>
    </div>
  </div>
</div>
-->

  <!-- This week's reviews -->
  <div class="container-fluid mt-5 mb-3 dashboard">
    <div class="background-dashboard">
      <div class="row ml-2 mt-2">
        <h3 class="h3-custom" style="color: #4f4f4f; margin: 2.083333% 0">
          This week's reviews
        </h3>
      </div>

      <div v-if="loadingReviews">
        <center>Loading...</center>
      </div>
      <div v-else>
        <div v-if="numDocsReviews === 0">
          <center>No papers found...</center>
        </div>

        <div v-for="doc in docsReviews" :key="doc.pmid">
          <!-- article  -->

          <!-- labels  -->
          <div class="row mt-3 ml-2">
            <div class="col-lg-8 col-md-9 col-sm-12">
              <div class="row">
                <p class="mb-3 p-custom-dashboard1">
                  <font-awesome-icon :icon="['fas', 'tag']" />
                  <b> {{ doc.discipline }}</b>
                  <span>: </span><span>{{ doc.category | formatFirebaseCategory  }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- date and journal  -->
          <div class="row ml-2 mt-3">
            <!-- remove mt-3 when labels active -->
            <div class="col-8">
              <div class="row">
                <p class="p-custom-dashboard1">{{ doc.date | formatFirebaseDate }}</p>
                <p class="p-custom-dashboard1">|</p>
                <p class="p-custom-dashboard1">{{ doc.jabbrv }}</p>
              </div>
            </div>

            <div class="col">
              <div class="row mx-auto"></div>
            </div>
          </div>

          <!-- title  -->
          <div class="row ml-2 sm-mr-2">
            
            <div class="col-lg-7 col-md-7 col-sm-11">
              <div class="row">
                <!-- <a target="_blank" href=""> -->
                <p class="p-custom-dashboard2">{{ doc.title }}</p>
                <!-- </a> -->
              </div>
            </div>

            <div class="col-lg-1 col-md-1 col-2">
              <tracking-link :uid=userProfile.uid :doi=doc.doi :pmid=doc.pmid />
            </div>

          </div>

          <!-- author  -->
          <div class="row ml-2">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <div class="row">
                <p class="p-custom-dashboard3">{{ doc.author }}</p>
              </div>
            </div>

            <div class="col-sm-0"></div>
            <div class="col-sm-0"></div>
          </div>

          <!-- abstract  -->
          <div class="row mb-3 ml-2 sm-mr-2">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="row">
                <p class="p-custom-dashboard1 text-justify" v-if="doc.bottomLine">
                  <b>Extracted summary:</b>
                  {{ doc.bottomLine | truncate(500, "...") }}
                </p>

                <p class="p-custom-dashboard1 text-justify" v-else>
                  <b>Abstract:</b> {{ doc.abstract | truncate(500, "...") }}

                  <div v-if="userProfile && userProfile.isAdmin">
                    <p><b>admin:</b></p>
                    <v-checkbox color="info" v-model=doc.selected class="" @change="toggleInterestingArt(doc.pmid, doc.selected)">
                    </v-checkbox>
                    <p>predicted discipline: {{ doc.classified_discipline }}</p>
                    <p>impact factor: {{ doc.impact_factor }}</p>
                  </div>


              </div>
            </div>
            <div class="col-lg col-md"></div>
          </div>
          <hr />

          <!-- END ARTICLE -->
        </div>
      </div>

      <!-- Paginator -->
      <div class="row">
        <div class="col-12 ml-2">
          <div class="text-center">
            <v-pagination v-model="pageReview" :length="numDocsReviews" @input="loadPageReview" :total-visible="7"></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This week's Reviews -->
  <!--
<div class="container-fluid mt-5 mb-3 dashboard">
  <div class="background-dashboard">
    <div class="row ml-2 mt-2">
      <h3 class="h3-custom" style="color: #4F4F4F; margin: 2.083333% 0;">This week's reviews</h3>
    </div>
    <div class="row ml-2">
      <p class="p-custom-dashboard1">No new reviews were found...</p>
    </div>
  </div>
</div>
-->
  <!-- unsubscribe button -->
  <div class="container-sm">
    <div class="salutation">
      <div class="row mt-12 mb-4">
        <div class="col text-center">
          <a @click="toggleSub">
            <button type="button" class="btn btn-unsubscribe no-y-padding" style="border-radius: 8px">
              <div v-if="userProfile.alert">
                <p class="p-custom-unsubscribe no-wrap no-y-padding">
                  unsubscribe from weekly mail
                </p>
              </div>
              <div v-else>
                <p class="p-custom-unsubscribe no-wrap no-y-padding">
                  subscribe to weekly mail
                </p>
              </div>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import disciplines from "../lib/variables";
import {
  mapActions,
  mapState
} from "vuex";
import intersection from "lodash/intersection";
import TrackingLink from "../components/TrackingLink";
import PICO from "../components/PICO.vue";

export default {
  components: {
    TrackingLink,
    PICO
  },
  data() {
    return {
      page: 1,
      pageReview: 1,
      page_size: 5,
      disciplines,
      selectedDisciplines: [],
      selectedDisciplinesOld: [],
      selectedCategories: [],
      timeDelta: 14,
      referralEmail: "",
      referralSend: false,
      baseURL: process.env.NODE_ENV === 'production' ? "https://us-central1-rctalert-api.cloudfunctions.net/app" : "http://localhost:5001/rctalert-api/us-central1/app",
    };
  },
  mounted() {
    this.selectedDisciplines = this.userProfile.disciplines;
    this.selectedCategories = this.userProfile.categories;
    this.reloadPage();
  },
  computed: {
    ...mapState([
      "docs",
      "docsReviews",
      "numDocs",
      "numDocsReviews",
      "userProfile",
      "loadingRcts",
      "loadingReviews",
    ]),
    categories() {
      const categories = new Set();

      disciplines
        .filter((discipline) =>
          this.selectedDisciplines && this.selectedDisciplines.includes(discipline.id)
        )
        .forEach((discipline) => {
          (discipline.categories || []).forEach((cat) => categories.add(cat));
        });

      return [...categories];
    },
    allCategories() {
      return this.selectedCategories && this.selectedCategories.length === this.categories.length;
    },
    someCategories() {
      return this.selectedCategories && this.selectedCategories.length > 0 && !this.allCategories;
    },
    iconCategories() {
      if (this.allCategories) {
        return 'mdi-check-box-outline';
      }
      if (this.someCategories) {
        return 'mdi-checkbox-blank-outline';
      }
      return 'mdi-checkbox-blank-circle-outline';
    },
  },
  watch: {

    userProfile: function () {
      if (this.userProfile.disciplines && this.userProfile.categories) {
        this.selectedDisciplines = this.userProfile.disciplines;
        this.selectedCategories = this.userProfile.categories;
      }
      this.reloadPage();
    },
  },
  methods: {
    ...mapActions(["loadDocs", "updateProfile", "toggleSubscription", "sendReferral", "toggleInterestingArticle"]),
    //get rcts from server
    loadPage() {

      this.loadDocs({
        selectedDisciplines: this.selectedDisciplines,
        selectedCategories: this.selectedCategories,
        timeDelta: this.timeDelta,
        page: this.page,
        type: "rct",
      });

    },
    //get reviews from server
    loadPageReview() {

      this.loadDocs({
        selectedDisciplines: this.selectedDisciplines,
        selectedCategories: this.selectedCategories,
        timeDelta: this.timeDelta,
        page: this.pageReview,
        type: "review",
      });

    },
    reloadPage() {

      this.loadPage();
      this.loadPageReview();
    },
    toggleSub() {
      this.toggleSubscription(this.userProfile);
    },
    toggleInterestingArt(pmid, selected){

      this.toggleInterestingArticle({pmid, selected});
    },
    saveProfile() {
      this.userProfile.disciplines = this.selectedDisciplines;

      this.userProfile.categories = intersection(
        this.selectedCategories,
        this.categories.map((cat) => cat.id)
      );
      this.updateProfile(this.userProfile);

      this.pageReview = 1;
      this.page = 1;

      this.reloadPage();
    },
    sendReferralPressed() {
      this.sendReferral({
        referralEmail: this.referralEmail
      });
      this.referralSend = true;
    },
    remove(item) {
      this.selectedCategories.splice(this.selectedCategories.indexOf(item), 1);
      this.selectedCategories = [...this.selectedCategories];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allCategories) {
          this.selectedCategories = [];
        } else {
          this.selectedCategories = this.categories.map((item) => {
            return item.id;
          });
        }
      })
    },

    toggleNew() {
      const difDiscipline = this.selectedDisciplines.filter(x => !this.selectedDisciplinesOld.includes(x));

      const categories = new Set();
      disciplines
        .filter((discipline) =>
          difDiscipline.includes(discipline.id)
        )
        .forEach((discipline) => {
          (discipline.categories || []).forEach((cat) => categories.add(cat.id));
        });

      this.selectedCategories.push(...categories);

      this.selectedDisciplinesOld = this.selectedDisciplines;

    },

  },
};
</script>
