<template>
<!-- content -->
<v-container>
  <v-row>
    <v-col cols=3></v-col>
    <v-col>
      <h3 class="mb-10 ml-2">FAQ</h3>
    </v-col>
    <v-col cols=3></v-col>
  </v-row>

   <v-row >
     <v-col cols=3></v-col>
    <v-col class="">
      <v-btn
      block
        class="ma-2"
        color="#70C1D1"
        @click="expand = !expand"
      >
      <v-icon>mdi-chevron-down</v-icon>
       Are there any costs?
      </v-btn>

      <v-expand-transition>
        <v-card
          v-show="expand"
          elevation='0'
          class="ml-2"
          
        >  <p class="text-justify p-custom">
               No! For the moment, all subscriptions are completely free.
            </p>
        </v-card>
      </v-expand-transition>
    </v-col>
    <v-col cols=3></v-col>
  </v-row>

     <v-row >
     <v-col cols=3></v-col>
    <v-col class="">
      <v-btn
      block
        class="ma-2"
        color="#70C1D1"
        @click="expand2 = !expand2"
      >
      <v-icon>mdi-chevron-down</v-icon>
       Can I receive customized clinical e-alerts?
      </v-btn>

      <v-expand-transition>
        <v-card
          v-show="expand2"
          elevation='0'
          class="ml-2"
          
        >  <p class="p-custom text-justify">
                Yes, you can fully customize your clinical e-alerts if you prefer. You can try our customized queries and e-alert service <a href="https://picosearch.io/" target="_blank" style="color: #1717D9;">here.</a> 
            </p>
        </v-card>
      </v-expand-transition>
    </v-col>
    <v-col cols=3></v-col>
  </v-row>

    <v-row >
     <v-col cols=3></v-col>
    <v-col class="">
      <v-btn
      block
        class="ma-2"
        color="#70C1D1"
        @click="expand3 = !expand3"
      >
      <v-icon>mdi-chevron-down</v-icon>
       I do not receive mail alerts after subscribing.
      </v-btn>

      <v-expand-transition>
        <v-card
          v-show="expand3"
          elevation='0'
          class="ml-2"
          
        >  <p class="p-custom text-justify">
                 If you have not received any mail after subscribing, please check your spam folder and move our e-mail to your inbox. If you still cannot find our 
                e-mail, please make sure you have entered a valid e-mail address. If our e-alerts seem not to arrive at your e-mail address, you might try to subscribe again with another e-mail address. 
            </p>
        </v-card>
      </v-expand-transition>
    </v-col>
    <v-col cols=3></v-col>
  </v-row>

      <v-row >
     <v-col cols=3></v-col>
    <v-col class="">
      <v-btn
      block
        class="ma-2"
        color="#70C1D1"
        @click="expand4 = !expand4"
      >
      <v-icon>mdi-chevron-down</v-icon>
       I cannot log in.
      </v-btn>

      <v-expand-transition>
        <v-card
          v-show="expand4"
          elevation='0'
          class="ml-2"
          
        >  <p class="p-custom text-justify">
                 If you have not received a link in your inbox for login after pressing 'send login link', please check your spam folder and move the e-mail with login link to your inbox.
               Please make sure you have entered a valid e-mail address. If a login link does not seem to arrive at your e-mail address, you might try to subscribe again with another e-mail address. 
             </p>
        </v-card>
      </v-expand-transition>
    </v-col>
    <v-col cols=3></v-col>
  </v-row>

   <v-row >
     <v-col cols=3></v-col>
    <v-col class="">
      <v-btn
      block
        class="ma-2"
        color="#70C1D1"
        @click="expand5 = !expand5"
      >
      <v-icon>mdi-chevron-down</v-icon>
       How can I help improve RCTAlert?
      </v-btn>

      <v-expand-transition>
        <v-card
          v-show="expand5"
          elevation='0'
          class="ml-2"
          
        >  <p class="p-custom text-justify">
                  We appreciate all the feedback we can get. If you want to share any thoughts, or have any ideas how to improve our product, please contact us <a href="mailto:contact@rctalert.com">here</a>.
              </p>
        </v-card>
      </v-expand-transition>
    </v-col>
         <v-col cols=3></v-col>
  </v-row>
          

    <!-- bottom content -->
    <div class="bottom-content">
        <div class="container">
            <div class="row">
                <div class="mx-auto">
                    <router-link to="register">
                        <!-- replace link -->
                        <button class="btn-subscribe-bottom" type="button" name="button">I want RCT alerts!</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

</v-container>
</template>

<script>
  export default {
    data: () => ({
      expand: false,
      expand2: false,
      expand3: false,
      expand4: false,
      expand5: false,
    }),
  }
</script>

